export const activitiesBidStatusFields = [
  { value: '', label: 'All' },
  { value: 'AC', label: 'Active' },
  { value: 'AW', label: 'Awarded' },
  { value: 'CA', label: 'Cancelled' },
  { value: 'DE', label: 'Deleted' },
  { value: 'OP', label: 'Under Evaluation' },
  { value: 'RA', label: 'Recommendation of Award' },
  { value: 'RJ', label: 'Rejected' },
];

export enum BidCategory {
  Active = 'AC',
  Awarded = 'AW',
  Cancelled = 'CN',
  Deleted = 'DE',
  NoData = 'ND',
  UnderEvaluation = 'OP',
  RecommendationOfAward = 'RA',
  Rejected = 'RJ',
  Upcoming = 'UP',
  Published = 'PB',
  Completed = 'CP',
  None = '',
  MyBids = 'MB',
}

/** BidExternalStatusType is a dependency for several of the definitions after it, thus the break in alphabetization */
export enum BidExternalStatusType {
  Active = 'AC',
  Awarded = 'AW',
  Cancelled = 'CN',
  Completed = 'CP',
  Deleted = 'DE',
  NoData = 'ND',
  None = '',
  Published = 'PB',
  RecommendationOfAward = 'RA',
  Rejected = 'RJ',
  UnderEvaluation = 'OP',
  Upcoming = 'UP',
}

export enum BidInternalStatusType {
  Broadcast = 'BR',
  Completed = 'CP',
  Deleted = 'DE',
  NoData = 'ND',
  None = '',
  OverrideBroadcastRules = 'OV',
  Pending = 'PN',
  ReadyForBroadcast = 'RB',
  WizardComplete = 'WC',
}

export const allowForwardNavStatuses = [
  BidExternalStatusType.Active,
  BidExternalStatusType.Awarded,
  BidExternalStatusType.Cancelled,
  BidExternalStatusType.Deleted,
  BidExternalStatusType.NoData,
  BidExternalStatusType.RecommendationOfAward,
  BidExternalStatusType.Rejected,
  BidExternalStatusType.UnderEvaluation,
  // The following statuses are not included:
  // 'CP, Completed
  // '', None
  // 'PB', Published
  // 'UP', Upcoming
];
export const bidExternalStatusNames = {
  Active: BidExternalStatusType.Active,
  Awarded: BidExternalStatusType.Awarded,
  Cancelled: BidExternalStatusType.Cancelled,
  Completed: BidExternalStatusType.Completed,
  Deleted: BidExternalStatusType.Deleted,
  'No Data': BidExternalStatusType.NoData,
  'Under Evaluation': BidExternalStatusType.UnderEvaluation,
  Published: BidExternalStatusType.Published,
  'Recommendation of Award': BidExternalStatusType.RecommendationOfAward,
  Rejected: BidExternalStatusType.Rejected,
  Upcoming: BidExternalStatusType.Upcoming,
};
export const awardeeStatus = [
  BidExternalStatusType.Awarded,
  BidExternalStatusType.RecommendationOfAward,
  BidExternalStatusType.UnderEvaluation,
];
export const bidActiveEditableStatus = [BidExternalStatusType.Active];
export const bidFullEditableStatus = [BidExternalStatusType.Upcoming];
export const bidNonEditableStatus = [BidExternalStatusType.Deleted, BidExternalStatusType.NoData];
export const bidReportsActivityPaginationsOptions = [
  { label: '10', value: 10 },
  { label: '50', value: 50 },
];
export const bidSemiEditableStatus = [
  BidExternalStatusType.Awarded,
  BidExternalStatusType.Cancelled,
  BidExternalStatusType.Rejected,
  BidExternalStatusType.UnderEvaluation,
];
export const bidTypeFields = [
  { fieldname: 'bidType', fieldlabel: 'Bid Type', width: 200 },
  { fieldname: 'bidTypeDesc', fieldlabel: 'Bid Type Description' },
];

export const BuyerEbidStatus = [
  { label: 'Awarded', value: 'AW' },
  { label: 'Complete', value: 'CP' },
  { label: 'Deleted', value: 'DE' },
  { label: 'Incomplete', value: 'IN' },
  { label: 'Non-Compliant', value: 'NC' },
  { label: 'Updated', value: 'UP' },
];

export const eBidDocumentFields = [
  { fieldname: 'description', fieldlabel: 'Document Description', width: 320 },
  { fieldname: 'sortOrder', fieldlabel: 'Display Order' },
];

export const EbidresponseSortFields = { name: 'Supplier', responseDate: 'Date' };

export const reportotherbidStatus = 'AC,AW,CN,DE,ND,OP,RA,RJ,UP,CP';

export const reportbidStatus = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: BidExternalStatusType.Active },
  { label: 'Awarded', value: BidExternalStatusType.Awarded },
  { label: 'Cancelled', value: BidExternalStatusType.Cancelled },
  { label: 'Completed', value: BidExternalStatusType.Completed },
  { label: 'Deleted', value: BidExternalStatusType.Deleted },
  { label: 'No Data', value: BidExternalStatusType.NoData },
  { label: 'Recommendation of Award', value: BidExternalStatusType.RecommendationOfAward },
  { label: 'Rejected', value: BidExternalStatusType.Rejected },
  { label: 'Under Evaluation', value: BidExternalStatusType.UnderEvaluation },
  { label: 'Upcoming', value: BidExternalStatusType.Upcoming },
];

export const bonfireBannerLinks = '';
export const extendedNetworkText = '- Demandstar Extended Network';
export const partOfExtNetworkText = 'Part of Demandstar Extended Network';
export const bonfireVendorRegistration = 'https://vendor.bonfirehub.com/subscribe';

export const bidsDataConstantsLeftMenu = [
  { title: 'Active', categoryType: BidCategory.Active, count: 0 },
  { title: 'Upcoming', categoryType: BidCategory.Upcoming, count: 0 },
  { title: 'Under Evaluation', categoryType: BidCategory.UnderEvaluation, count: 0 },
  { title: 'Recommendation Of Award', categoryType: BidCategory.RecommendationOfAward, count: 0 },
  { title: 'Awarded', categoryType: BidCategory.Awarded, count: 0 },
  { title: 'Completed', categoryType: BidCategory.Completed, count: 0 },
  { title: 'Cancelled', categoryType: BidCategory.Cancelled, count: 0 },
  { title: 'My Bids', categoryType: BidCategory.MyBids, count: 0 },
];
