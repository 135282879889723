//TOREFACTOR - how is this different / the same as the other datepicker.tsx?

import moment, { Moment } from 'moment-timezone';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
//import { SingleDatePicker } from 'react-dates';
import { useDispatch } from 'react-redux';
import * as actionTypes from '../../../../store/actionTypes';
import { datePickerYear, invalidDateMessage, usaDateFormat } from '../../../../utils/constants';
import { deprecatedConvertUSAFormat, validateTypedDate } from '../../../../utils/helpers';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import useOnClickOutside from 'src/utils/helpers/useOutside';
import transition from 'react-element-popper/animations/transition';
import opacity from 'react-element-popper/animations/opacity';

interface DatePickerProps {
  handleChange?: (name: string, date: any) => void;
  type?: any;
  disabled?: any;
  label?: string;
  meta?: any;
  optional?: any;
  input?: any;
  placeholder?: string;
  minDate?: any;
  maxDate?: any;
  parentClass?: string;
  showClearDate?: any;
  showDefaultInputIcon?: any;
  isOutsideRange?: any;
  numberOfMonths?: any;
  maxLength?: any;
  classNames?: any;
  field: any;
  form: any;
  innerClass?: any;
}

function DatepickerField(props: DatePickerProps) {
  const {
    handleChange = () => {},
    disabled = false,
    label,
    // name = '',
    // value = '',
    meta = {},
    optional,
    input,
    placeholder = usaDateFormat,
    minDate = moment(),
    maxDate,
    parentClass,
    showClearDate = false,
    isOutsideRange = false,
    numberOfMonths = 1,
    field = {},
    form = {},
    innerClass = '',
  } = props;
  const { name = '' } = field;
  const { setFieldValue, errors = false, touched = false } = form;
  const { showDefaultInputIcon = true } = props;
  //   const { touched, error } = meta || {};
  const [focused, setFocused] = useState(false);
  const [internalError, setInternalError] = useState('');
  //   const idGen = reduxform ? `single_date_redux_${input.name}` : `single_date_${name}`;
  const idGen = `${name}`;
  const dispatch = useDispatch();


  const datePickerRef = useRef<any>();
  const [state, setState] = useState({ format: 'MM/DD/YYYY' });
  let dateStringValue = field.value ? field.value : '';
  if (field.value && moment.isMoment(field.value)) {
    dateStringValue = field.value.format(usaDateFormat);
  }


  const minimumDate = minDate ? new Date(minDate.format(usaDateFormat)) : '';
  const maximumDate = maxDate ? new Date(maxDate.format(usaDateFormat)) : '';

  const [initialDate, setInitialDate] = useState(dateStringValue);


  const handleDatePickerClose = useCallback(
    () => datePickerRef.current.closeCalendar(),
    [datePickerRef],
  );

  useOnClickOutside(datePickerRef, handleDatePickerClose);

  const settingErrorFn = useCallback(
    (value: string) => {
      setInternalError(value);
      dispatch({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { invaliddateerror: value },
      });
    },
    [dispatch],
  );

  const onSingleDateChange = useCallback(
    (selectedDate: any, format = state.format) => {
      if (selectedDate !== null && selectedDate.isValid && selectedDate.year > 2000) {
        const date = new DateObject(selectedDate).format();
        setFieldValue(name, date);
        settingErrorFn('');
      } else if (!selectedDate) {
        setFieldValue(name, '');
      } else if (!selectedDate.isValid && selectedDate.year < 2000) {
        settingErrorFn(invalidDateMessage);
      }
    },
    [setFieldValue, name, settingErrorFn, state.format],
  );

  return (
    <div
      className={
        'datePickerWrapper ' +
        parentClass +
        ((touched[name] && errors[name]) || internalError ? ' errorWrapper' : '')
      }
    >
      <div title={usaDateFormat} className={'inputWrapper' + ' ' + innerClass}>
        <label title={usaDateFormat}>{label}</label>
        <DatePicker
          ref={datePickerRef}
          id='datepickerId'
          placeholder='MM/DD/YYYY'
          onChange={onSingleDateChange}
          value={dateStringValue}
          format='MM/DD/YYYY'
          containerClassName='DateRangePicker'
          minDate={minimumDate}
          maxDate={maximumDate}
          highlightToday={false}
          onOpenPickNewDate={false}
          disabled={disabled}
          animations={[
            opacity(),
            transition({
              from: 40,
              transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)',
            }),
          ]}
          monthYearSeparator="|"
        />
        <i className='mdi mdi-calendar'></i>
        {optional === true ? <span>(optional)</span> : optional ? <span>{optional}</span> : ''}
        <div className='errorMsg'>
          {(touched[name] && errors[name]) || internalError ? (
            <>{<span>{internalError ? internalError : errors[name]}</span>}</>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default memo(DatepickerField);
