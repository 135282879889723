import { memo } from 'react';

import { DemandStarSupportEmail } from 'src/utils/texts/common/emails';
import { supportPageLink } from 'src/utils/texts/common/navigation';

function GettingStart() {
  return (
    <>
      <p>
        DemandStar's Support Team is available from 8:00 am to 8:00 pm ET Monday to Friday,
        excluding statutory holidays. Send us an email at {DemandStarSupportEmail} for the quickest
        response.
      </p>
      <p>
        If you need assistance after hours, check out our{' '}
        <a href={supportPageLink} target='_blank' rel="noreferrer">
          Help Centre
        </a>
        . If your question wasn't answered using resources on our Help Centre, someone will get in
        touch with you the following business day
      </p>
    </>
  );
}

export default memo(GettingStart);
