import * as agencyTexts from './agency';
import * as commonTexts from './common';
import * as supplierTexts from './supplier';

// NOTE: Do not add new text constants to this file
// Instead, create a page specific file and import it

export { agencyTexts, supplierTexts, commonTexts };

export const confirmbroadcastText =
  'You are trying to proceed without adding any commodity codes. This is how suppliers are matched to and notified of your bid. DemandStar allows you to skip this, but we strongly encourage you to add commodity codes now.';

export const confirmbroadcastsubText =
  'Skipping this step will result in dramatically fewer suppliers seeing and responding to your bid.';

export const broadcastconfirmText = 'I understand, and want to skip adding commodity codes';

export const broadcastcancelText = 'Stay on this page and add Commodity Codes';

export const confirmbroadcastdocumentsText =
  'You have not added any documents. Click "Add Document" to add documents related to awardees.';

export const deleteActiveBidDocConfirmMsgText =
  'This bid has been already broadcasted. Attachment document will be deleted permanently and it cannot be restored. Are you sure you want to delete this document?';

export const deleteDocConfirmMsgText = 'Are you sure you want to delete this document?';

export const approveConfirmMsgText =
  // 'If you are sure that the selected document fully meets your requirements,
  // click Approve to approve the document for distribution or Cancel to return to the previous screen.';
  'Are you sure this is the document you mean to upload:';

export const continueebidconfirmmsgText =
  'You have opted out of eBidding. Are you sure you want to continue?';

export const filtersText = {
  myBids: 'Only Show My Bids',
  bidsNotified: 'Bids Notifications',
  orderedBids: 'Ordered Bids',
  watchedBids: 'Watched Bids',
  commodityMatches: 'Commodity Code Matches',
  ebiddingAvailable: 'eBidding Available',
  agencyMemberId: 'Government Agency',
  bidName: 'Bid Name',
  state: 'State',
  location: 'Location',
  radius: 'Radius',
  bidIdentifier: 'Bid Identifier',
  industry: 'Commodity Code / Industry',
  fiscalYear: 'Fiscal Year',
  dueDate: 'Due Date Range',
  includeExternalBids: 'External Bids',
};

// no data found messages
export const noResultMsg = {
  commonMsg: 'No Data Available',
  bidSearch:
    'There are no results found for your current search. Please try changing your search conditions.',
  bidSupplier:
    'You have not yet been notified of new bid opportunities. Use the "All bids in the system" search criteria in Show bids filter to find existing bids',
  quoteSupplier: 'You have not yet been invited to any quote requests.',
  quoteSupplierUpdateProfile:
    'Update your profile so you can get updates on this and attract the attention of government agencies.',
  quoteSupplierTips:
    'Would you like to learn more about how to attract more agencies? Visit our blog for the latest tips on how to grow your business.',
};

export const loadingMsg = 'Loading data';

export const bidResultMsg =
  'There are no results found for your current search. Please try changing your search conditions.';

export const noresultFound = 'No Data Available';

export const supplierBidNoResultMsg =
  'You have not yet been notified of new bid opportunities. Use the "All bids in the system" search criteria in Show bids filter to find existing bids';

export const quotesupplierNoResultMsg = 'You have not yet been invited to any quote requests.';

export const quotesupplierNoResultMsg2 =
  'Update your profile so you can get updates on this and attract the attention of government agencies.';

export const quotesupplierNoResultMsg3 =
  'Would you like to learn more about how to attract more agencies? Visit our blog for the latest tips on how to grow your business.';

export const leavingAlertMessage =
  'This page has unsaved changes. Are you sure you want to leave this page?';

export const commodityLeavingAlertMessage =
  'You have not saved the changes to your commodity codes. Do you want to save your changes or discard them?';

export const agencyLeavingAlertMessage =
  'You have not saved the changes to your Agency Page. Do you want to discard your changes or save them?';

export const bidResponseLeavingAlertMessage =
  "You haven't completed and submitted this bid yet, are you sure you want to navigate away?";

export const responseDocumentDeleteAlertMessage =
  'This will cause your response to be marked as Incomplete. Leaving your response Incomplete may disqualify you from consideration. Do you want to continue?';

export const noCommodityCodesTexts =
  'You are not currently subscribed to any Commodity Codes. These describe your business so we can match you to opportunities.  ';

export const processConfirmText =
  'There are documents still being processed that will need to be approved before suppliers are notified. Do you want to wait on this page while documents are processed and approve them now, or do you want to leave this page and approve these documents and notify suppliers later?';

export const approveConfirmText =
  'There are still documents that need to be approved before suppliers will be notified. Do you want to approve these documents and notify suppliers later, or stay on this page and approve these documents now?';

export const activefilterText = 'Filters in use:';

export const eBidslearnmoreText = 'Learn more about our free eBidding platform';

export const eBidsHeadingText =
  'eBidding - Receive Supplier Responses Electronically Through DemandStar';
