import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { dashboardBidsList } from '../../store/services/shared';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';
import { track } from '../../utils/telemetry';

const GetDashboardBidsListData = (data: any) => {
  return dashboardBidsList(data);
};

export const useGetDashboardBidsListData = (data: any) => {
  return useQuery({
    queryKey: ['dashboardBids'],
    queryFn: async () => {
      try {
        return await  GetDashboardBidsListData(data);
      } catch (error) {
        track('GetDashboardBidsListData  -- ERROR:', { error });
        throw error;
      }
    },
    staleTime: STALE_TIMES.THIRTY_MINUTES,
  });
};
