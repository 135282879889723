import CryptoJS from 'crypto-js';

function encryptPassword(username:any, password:any) {
  try {
    const input = (username?.toUpperCase() || '') + password;
    const hash = CryptoJS.MD5(input).toString(CryptoJS.enc.Hex);
    return hash;
  } catch (error) {
    return error;
  }
}
export default encryptPassword;

export const checkPasswordBreached = async (password:string,opsID?:number) => {
  const hashedPassword = CryptoJS.SHA1(password).toString(CryptoJS.enc.Hex).toUpperCase();
  const prefix = hashedPassword.substring(0, 5);
  const suffix = hashedPassword.substring(5);
  const response = await fetch(`https://api.pwnedpasswords.com/range/${prefix}`);
  const data = await response.text();
  return opsID > 0 ? false : data.includes(suffix);
};

