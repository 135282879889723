import { useQuery } from '@tanstack/react-query';
import { getDashboardNetwork } from '../../store/services/shared';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';
import { track } from '../../utils/telemetry';

const GetDashboardNetworkData = (data: any) => {
  return getDashboardNetwork(data);
};

export const useGetDashboardNetworkData = (data: any) => {
  return useQuery({
    queryKey: ['dashboardNetworkData'],
    queryFn: async () => {
      try {
        return await GetDashboardNetworkData(data);
      } catch (error) {
        track('GetDashboardNetworkData  -- ERROR:', { error });
        throw error;
      }
    },
    staleTime: STALE_TIMES.THIRTY_MINUTES,
  });
};
