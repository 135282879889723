import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Flex, FlexContainer } from '@demandstar/components/styles';
import { DSButton } from '@demandstar/components/button';
import { Margin } from '@demandstar/components/styles';

import * as texts from './ManageContractDocuments.texts';
import {
  AddDocumentFormValues,
  submitAddDocument,
} from '../../documents/add-document/AddDocument.helpers';
import { AddContractDocumentForm } from '../../documents/add-document/AddContractDocumentForm';
import { Alert } from 'src/components/common/alert';
import { ContractDocumentsTable } from '../../documents/ContractDocumentsTable';
import { ContractWizardButtons } from '../ContractWizardButtons';
import { ContractWizardId } from '../useContractWizard';
import { documentAlertId } from '../../documents/ContractDocuments.alerts';
import { LoadableWrapper } from '../../../../../components/common/loading/LoadableWrapper';
import { scrollToTop } from 'src/utils/helpers';
import { useContractDetails } from 'src/features/contract-management/useContractDetails';
import { useContractDocuments } from '../../documents/useContractDocuments';

const AddDocumentsFormContainer = styled('div')`
  margin-top: ${Margin.Extra};
`;

export const ManageContractDocuments = () => {
  const { contractDetails } = useContractDetails();
  const contractId = contractDetails?.id;
  const { contractDocuments, contractDocumentsLoadable, uploadContractDocument } =
    useContractDocuments();
  const hasDocuments = contractDocuments && contractDocuments.length;

  const [addingDocument, setAddingDocument] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [triggerReset, setTriggerReset] = useState(false);
  const buttonText = uploading ? texts.uploading : contractId ? texts.saveAndAdd : texts.saveDoc;

  const toggleAddDocument = () => setAddingDocument(!addingDocument);

  const methods = useForm<AddDocumentFormValues>({
    mode: 'onChange',
  });
  const { getValues, reset, watch, formState } = methods;

  const EmptyForm = () => {
    if (!addingDocument) return true;
    const formValues = watch();
    const hasValue = Object.values(formValues).some(val => {
      if (val && typeof val === 'object' && 'length' in val) return val.length > 0;
      return !!val;
    });
    return !hasValue;
  };

  const isEmptyForm = EmptyForm();

  const canMoveForward = isEmptyForm || formState.isValid;

  const uploadDocument = async () => {
    if (!addingDocument || isEmptyForm) return true;

    const values = getValues();
    // Enter "uploading" state
    setUploading(true);

    // Upload document
    try {
      await submitAddDocument(uploadContractDocument, values, contractId);
      // Reset form
      setTriggerReset(true);
    } finally {
      // Exit "uploading" state
      setUploading(false);
      scrollToTop();
    }

    return true;
  };

  useEffect(() => {
    // Reset toggled true, show upload success alert -- TODO: standardize this around upload form(?)
    if (triggerReset) {
      setTriggerReset(false);
      reset();
    }
  }, [reset, triggerReset]);

  useEffect(() => {
    if (contractDocumentsLoadable.state === 'hasValue' && !hasDocuments && !addingDocument) {
      setAddingDocument(true);
    }
  }, [addingDocument, contractDocumentsLoadable.state, hasDocuments]);

  return (
    <>
      <LoadableWrapper loadable={contractDocumentsLoadable}>
        {hasDocuments ? (
          <ContractDocumentsTable data={contractDocuments} itemsPerPage={5} />
        ) : (
          texts.manageDocuments
        )}

        <AddDocumentsFormContainer>
          {addingDocument || !hasDocuments ? (
            <div data-testid='wizard-form-upload-document'>
              <Alert id={documentAlertId} allowDismiss />
              <FormProvider {...methods}>
                <AddContractDocumentForm />
                <FlexContainer justifyContent='flex-end'>
                  {!!hasDocuments && (
                    <DSButton type='secondary' inactive={uploading} onClick={toggleAddDocument}>
                      {texts.cancel}
                    </DSButton>
                  )}
                  <DSButton
                    inactive={!formState.isValid || uploading}
                    type='tertiary'
                    onClick={uploadDocument}
                  >
                    {buttonText}
                  </DSButton>
                </FlexContainer>
              </FormProvider>
            </div>
          ) : (
            <FlexContainer justifyContent='center'>
              <Flex grow={0}>
                <DSButton type='tertiary' onClick={toggleAddDocument}>
                  {texts.addAnotherDocument}
                </DSButton>
              </Flex>
            </FlexContainer>
          )}
        </AddDocumentsFormContainer>
      </LoadableWrapper>
      <ContractWizardButtons
        previousWizardId={ContractWizardId.SupplierDetails}
        nextWizardId={ContractWizardId.Review}
        onNext={uploadDocument}
        inactiveNext={uploading || !canMoveForward}
      />
    </>
  );
};
