import { useQuery } from '@tanstack/react-query';
import { getdashboarddataTracking } from '../../store/services/shared';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';
import { track } from '../../utils/telemetry';

const GetDashboardTrackingData = (data: any) => {
  return getdashboarddataTracking(data);
};

export const useGetDashboardTrackingData = (data: any) => {
  return useQuery({
    queryKey: ['dashboardTrackingData'],
    queryFn: async () => {
      try {
        return await GetDashboardTrackingData(data);
      } catch (error) {
        track('GetDashboardTrackingData  -- ERROR:', { error });
        throw error;
      }
    },
    staleTime: STALE_TIMES.THIRTY_MINUTES,
  });
};

