import { Suspense, useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import { FlexContainer, H2 } from '@demandstar/components/styles';

import { CancelSubscription, ModifyFreeAgency, ModifySubscription } from '../ops-actions';
import {
  cartRenewalState,
  selectedProductsState,
  upsellProductsState,
} from 'src/store/recoil/productState';
import { SubscriptionStatusWrapper, SubscriptionTableWrapper } from './styles';
import { useAccountInfo, useRefreshAccountInfo } from '../../../shared/hooks/useAccountInfo';
import { useProfileInfo, useRefreshProfileInfo } from '../../../shared/hooks/useProfileInfo';

import { Page } from '../styles';
import { ProductsTable } from '../products-table';
import { SubscriptionStatus } from '../subscription-status';
import { UpsellSection } from './upsell-section';
import { UserType } from 'src/types/accountinfo';
import { useSetBreadcrumb } from '../../../shared/hooks';
import { useAuthContext } from 'src/auth/AuthProvider';

/**
 * @description Page to display a user's current subscription status
 * @returns JSX.Element
 * @example CurrentSubscriptions()
 */
export const CurrentSubscriptions = () => {
  useRefreshAccountInfo();
  useRefreshProfileInfo();

  const { accountInfo } = useAccountInfo();
  const { profileInfo } = useProfileInfo();
  const { auth } = useAuthContext();

  const isOpsUser = Number(auth?.opsId) > 0;

  const resetSelectedProducts = useResetRecoilState(selectedProductsState);
  const resetUpsellProducts = useResetRecoilState(upsellProductsState);
  const resetCartRenewal = useResetRecoilState(cartRenewalState);

  const {
    expiryDate,
    expiredProducts = [],
    products = [],
    newProductIds = [],
    newTransaction = { cardLastFour: '', transactionAmount: 0 },
    userType = UserType.BasicSupplier,
  } = accountInfo || {};

  useEffect(() => {
    resetSelectedProducts();
  }, [resetSelectedProducts]);

  useEffect(() => {
    resetUpsellProducts();
  }, [resetUpsellProducts]);

  useEffect(() => {
    resetCartRenewal();
  }, [resetCartRenewal]);

  useSetBreadcrumb({
    page: 'Subscriptions',
    title: 'Subscriptions',
    altname: 'Subscriptions',
    name: 'Subscriptions',
  });

  return (
    <Suspense fallback={<></>}>
      <Page>
        <H2>Your DemandStar Subscription</H2>
        <UpsellSection />
        <SubscriptionTableWrapper>
          <ProductsTable
            products={products?.length > 0 ? products : expiredProducts}
            newProductIds={newProductIds}
            newTransaction={newTransaction}
          />
        </SubscriptionTableWrapper>
        {(products?.length > 0 || expiredProducts?.length > 0) && (
          <SubscriptionStatusWrapper>
            <SubscriptionStatus
              profileInfo={profileInfo}
              expiryDate={expiryDate}
              expiredProducts={products?.length ? [] : expiredProducts}
              userType={userType}
              isMainContact={Boolean(auth?.mainContact)}
            />
          </SubscriptionStatusWrapper>
        )}
        {isOpsUser && (
          <FlexContainer justifyContent='center'>
            <ModifyFreeAgency products={products} />

            {userType === UserType.PaidSupplier && (
              <ModifySubscription card={profileInfo && profileInfo?.card} expiryDate={expiryDate} />
            )}

            {userType === UserType.PaidSupplier && <CancelSubscription />}
          </FlexContainer>
        )}
      </Page>
    </Suspense>
  );
};
