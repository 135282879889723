import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DSButton } from '@demandstar/components/button';
import { DSLink } from '@demandstar/components/link';
import { DSAlert, DSCheckbox } from '@demandstar/components/index';
import { DSTextInput } from '@demandstar/components/inputs';
import { recoilRegistrationDataState } from 'src/store/recoil/registrationState';
import { registrationComponent } from 'src/utils/constants';
import { RegistrationData } from 'src/types/supplierregistration';
import { cleanInput, displayDate, getDisplayText, getStatusBarTheme, scrollToTop, toastFn } from 'src/utils/helpers';
import { useRegistration } from 'src/shared/hooks/useRegistration';
import { Buttons, Loader, NoResult } from 'src/components/customcontrols';
import { Link } from 'react-router-dom';

import { useGetBidTagsCognitiveSearch } from '../../../query/queries/cognitiveSearch/getBidtagsCognitiveSearchData';
import { useGetCommodityCodesCognitiveSearch } from '../../../query/queries/cognitiveSearch/getcommoditiyCognitiveSearchData';
import { CommodityCodesSpacedButtonWrapperMobile } from '../agency-selection/styles';
import { ModalPopUp } from '../../common/modals';
import { PopUpFlexContainerMobile } from '../../../library/styles';
import { Status } from '../../../library/constants';
import { commonTags, findLegacyCommodities, mergeCommodityCodes } from '../helpers';
import { CommodityState, useCommodities, useRefreshCommodities } from '../../../shared/hooks/useCommodity';
import { Commodity } from './LegacyCommodityCodes';
import { DisplayTags } from '../../shared/accountinfo/commoditycodes/editTagsAndCommodities/DispalyTags';
import { RelevantCognitiveBids } from '../../shared/accountinfo/commoditycodes/editTagsAndCommodities/RelevantCognitiveBids';
import { CognitiveCommodityCodes } from '../../shared/accountinfo/commoditycodes/editTagsAndCommodities/Suggested-SelectedCodes';

export const RegistrationCommodityCodeNew = () => {
    useRefreshCommodities();
    const { commodities } = useCommodities();
    const [codesFromLegacy, setCodesfromLegacy] = useState<Commodity[]>();
    const registrationData = useRecoilValue<RegistrationData>(recoilRegistrationDataState);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [confirmationPopup, toggleConfirmationPopup] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedCommodityIds, setSelectedCommodityIds] = useState([]);
    const { saveIncompleteRegistration } = useRegistration();
    const [searchQuery, setSearchQuery] = useState('');
    const [inputValue, setInputValue] = useState('');
    const { data: bidTagsData, isFetching: isFetchingBidTags } = useGetBidTagsCognitiveSearch(searchQuery, { enabled: searchQuery?.length > 0 });
    const { data: commodityCodesData, isFetching: isFetchingCommodityCodes } = useGetCommodityCodesCognitiveSearch(searchQuery, { enabled: searchQuery?.length > 0 });
    const mergedCodes = mergeCommodityCodes(commodityCodesData, codesFromLegacy) as any;
    const [checkedCommodities, setCheckedCommodities] = useState([]);

    let highlightedTagsArr = [];
    const suggestedTagsopt = commodityCodesData?.value?.flatMap(item => {
        const hightlights = item['@search.captions'] ? item['@search.captions'][0]?.highlights : '';
        if (hightlights) {
            const result = hightlights?.match(/<em>(.*?)<\/em>/g)?.map(val => {
                return val.replace(/<\/?em>/g, '');
            }) || [];
            highlightedTagsArr = [...highlightedTagsArr, ...result];
        }
    });
    const suggestedTags = [... new Set(
        highlightedTagsArr
            .join(',')
            .replace(/-/g, '')
            .replace('.', ',')
            .replace('(', '')
            .replace(')', '')
            .replace(':', ',')
            .split(',')
            .map(Item => Item.trim().toLowerCase())
            .filter(Item => Item)),
    ];

    /*const suggestedTags = commodityCodesData?.value
        .flatMap(item => item.trimmedtags ? item.trimmedtags.split(',').map(tag => tag.trim().toLowerCase()) : [])
        .filter(tag => tag?.length > 0) || [];*/

    const sortedUniqueSuggestedTags = suggestedTags
        //?.flatMap(tag => tag.toLowerCase().split(' '))
        //.map(word => word.trim())
        //.filter(word => word !== '')
        .filter(word => word !== '' && word.length >= 3)
        .filter(word => {
            const lowerCaseSelectedTags = selectedTags.map(selectedTag => selectedTag.toLowerCase().trim());
            const lowerCaseCommonTags = commonTags.map(commonTag => commonTag.toLowerCase().trim());
            return (
                !lowerCaseSelectedTags.includes(word) &&
                !lowerCaseCommonTags.includes(word)
            );
        }) || [];

    //const sortedUniqueSuggestedTags = sortTagsByFrequency(uniqueSuggestedTags) || [];



    const hasFreeAgency = () => {
        return !!registrationData.freeAgency;
    };

    const confirmSkip = () => {
        moveToNextPage([], [], [], searchQuery, false);
        toggleConfirmationPopup(false);
    };

    const handleSearch = () => {
        setIsSearchClicked(true);
        setSearchQuery(cleanInput(inputValue));
    };


    const moveToNextPage = async (commodityIds: number[], selectedCommoditycodes: any[], selectedTags: any[], lastAISearch: string, commodityAddedFromLegacy: boolean) => {
        const regData = { ...registrationData, commodityIds, selectedCommoditycodes, selectedTags, lastAISearch, commodityAddedFromLegacy };
        await saveIncompleteRegistration(registrationComponent.ChooseSubscription, regData);
    };

    const goBack = async () => {
        await saveIncompleteRegistration(registrationComponent.ChooseAgency, registrationData);
    };

    const confirmContinue = () => {
        const commodityIds = checkedCommodities.map((comm: any) => comm.commodityid);
        setSelectedCommodityIds(commodityIds);
        const commodityAddedFromLegacy = false;
        moveToNextPage(commodityIds, checkedCommodities, selectedTags, searchQuery, commodityAddedFromLegacy);
    };

    const handleTagSelect = (tag: string) => {
        const cleanedTag = tag.replace(/\s*\(\d+\/10\)$/, '');

        setSelectedTags(prevTags => {
            const updatedTags = [...prevTags, cleanedTag];

            const suggestedCodes = commodityCodesData?.value.filter(commodity =>
                commodity.trimmedtags?.split(',').map(tag => tag.trim().toLowerCase()).includes(cleanedTag.toLowerCase()),
            ) || [];

            setCheckedCommodities(prevCodes => {
                const newCodes = [...prevCodes, ...suggestedCodes];
                const parentCodes = new Set(
                    newCodes
                        .filter(code => code.fullcode?.endsWith('00'))
                        .map(code => code.fullcode),
                );
                const filteredCodes = newCodes?.filter((code, index, self) => {
                    const isChildOfExistingParent =
                        !code.fullcode?.endsWith('00') &&
                        parentCodes?.has(code.fullcode?.slice(0, -2) + '00');
                    return (
                        !isChildOfExistingParent &&
                        index === self.findIndex(c => c.commodityid === code.commodityid)
                    );
                });
                return filteredCodes;
            });

            return updatedTags;
        });
    };


    const handleTagRemove = (tag: string) => {
        const updatedTags = selectedTags.filter(t => t !== tag);
        setSelectedTags(updatedTags);


        const remainingSuggestedCodes = updatedTags.flatMap((remainingTag) => {
            return commodityCodesData?.value.filter(commodity =>
                commodity.trimmedtags?.split(',').map(t => t.trim().toLowerCase()).includes(remainingTag.toLowerCase()),
            ) || [];
        });

        const uniqueSuggestedCodes = remainingSuggestedCodes.filter((code, index, self) =>
            index === self.findIndex(c => c.commodityid === code.commodityid),
        );
        setCheckedCommodities(prevCodes => {
            return prevCodes.filter(code =>
                uniqueSuggestedCodes.some(suggestedCode => suggestedCode.commodityid === code.commodityid) ||
                code.manuallyAdded,
            );
        });
    };

    const handleCheckboxChange = (commodity) => {
        setCheckedCommodities((prevCheckedCommodities) => {
            const isCommodityChecked = prevCheckedCommodities.some(
                (item) => item.commodityid === commodity.commodityid,
            );
            const isParentCommodity = commodity.fullcode.endsWith('00');

            if (isCommodityChecked) {
                return prevCheckedCommodities.filter(
                    (item) => item.commodityid !== commodity.commodityid,
                );
            } else {
                if (isParentCommodity) {
                    const parentPrefix = commodity.fullcode.slice(0, -2);
                    const updatedCommodities = prevCheckedCommodities.filter(
                        (item) => !item.fullcode.startsWith(parentPrefix) || item.fullcode.endsWith('00'),
                    );
                    toastFn('success', `Codes under ${commodity.commodity} are selected automatically `);
                    return [...updatedCommodities, { ...commodity, manuallyAdded: true }];
                }
                return [...prevCheckedCommodities, { ...commodity, manuallyAdded: true }];
            }
        });

    };

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        setSelectedCommodityIds(registrationData.commodityIds || []);
        setSelectedTags(registrationData.selectedTags || []);
        setCheckedCommodities(registrationData.selectedCommoditycodes || []);
        setSearchQuery(registrationData.lastAISearch || '');
        setInputValue(registrationData.lastAISearch || '');
    }, [registrationData]);

    useEffect(() => {
        if (commodities && isSearchClicked) {
            const commoMap: Map<string, Commodity> = new Map();

            commodities.forEach((comm: CommodityState) => {
                const formattedCode = `${comm.commodityGroup}-${comm.commodityCategory}-${comm.commodityCode}`;

                commoMap.set(formattedCode, {
                    commodityCategory: comm.commodityCategory,
                    commodityCode: comm.commodityCode,
                    commodityDescription: comm.commodityDescription?.toUpperCase(),
                    commodityGroup: comm.commodityGroup,
                    commodityId: comm.commodityId,
                    formattedCode: formattedCode,
                    fullCode: formattedCode,
                    isSelected: false,
                });
            });

            const foundCommodities = findLegacyCommodities(commoMap, inputValue);
            setCodesfromLegacy(foundCommodities);
        }
    }, [inputValue, commodities, isSearchClicked]);


    return (
        <>
            {isFetchingBidTags || isFetchingCommodityCodes ? <Loader loading={isFetchingBidTags || isFetchingCommodityCodes} /> :

                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='row'>
                        </div>
                        {hasFreeAgency() && (
                            <div className='row'>
                                <div className='col-sm-12 col-xl-9 col-md-12'>
                                    <p className='reg-intro no-top-padding '>
                                        Take control of your bid notification experience and maximize your chances of finding
                                        opportunities that are tailored to your needs. Add your key tags here and let us assist
                                        you in uncovering the most relevant and valuable bid opportunities in your industry.
                                    </p>
                                </div>
                            </div>)}
                        {!hasFreeAgency() && (
                            <div className='row'>
                                <div className='col-sm-12 col-xl-8 col-md-8'>
                                    <DSAlert
                                        header={"You haven't selected a free agency."}
                                        link={
                                            <DSLink onClick={goBack}>
                                                Please go back and select an agency for the best experience.
                                            </DSLink>
                                        }
                                        type={Status.Warning}
                                    >
                                        Without an agency selected, we can&apos;t match you up with commodity codes.
                                    </DSAlert>
                                </div>
                            </div>
                        )}
                        {hasFreeAgency() && (<>
                            <div className='row'>
                                <div className='col-sm-7 col-xl-7 col-md-7 mt-2'>
                                    <DSTextInput
                                        name={'Cognitive search bar'}
                                        value={inputValue}
                                        onChange={(value: string) => setInputValue(value)}
                                        placeholder={'Example : Infrastructure , Automobiles , Dairy , Agriculture '}
                                    />
                                </div>
                                <div className='col-sm-5 col-xl-5 col-md-5 '>
                                    <Buttons
                                        classNames='bttn-primary mt-1'
                                        text='Search'
                                        title='Search'
                                        type='submit'
                                        onClick={handleSearch}
                                        disable={inputValue.length < 3}
                                    />
                                </div>
                            </div>
                            <DisplayTags
                                suggestedTags={suggestedTags}
                                selectedTags={selectedTags}
                                sortedUniqueSuggestedTags={sortedUniqueSuggestedTags}
                                handleTagSelect={handleTagSelect}
                                handleTagRemove={handleTagRemove}
                            />
                            <div className='row justify-content-between' >
                                <div className='col-sm-12 col-xl-7 col-md-12 mt-2'>
                                    <RelevantCognitiveBids
                                        bidTagsData={bidTagsData}
                                        isSearchClicked={isSearchClicked}
                                        pageFor={'registration'}
                                    />
                                </div>


                                <div className='col-sm-12 col-xl-5 col-md-12  '>

                                    <div className='row '>
                                        {hasFreeAgency() && (
                                            <div className='col mt-2'>
                                                <CognitiveCommodityCodes
                                                    mergedCodes={mergedCodes}
                                                    checkedCommodities={checkedCommodities}
                                                    handleCheckboxChange={handleCheckboxChange}
                                                    formattedExistingCodes={[]}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>)}
                        <div className='row pt-3 mt-3'>
                            <div className='col-sm-12 col-xl-8 col-md-12 mr-2 '>
                                <span>
                                    <DSButton type='secondary' onClick={goBack}>
                                        Go Back
                                    </DSButton>
                                </span>
                                <span className='bttn-continue'>
                                    <CommodityCodesSpacedButtonWrapperMobile>
                                        {hasFreeAgency() && (
                                            <span className='mr-3'>
                                                <DSLink onClick={() => toggleConfirmationPopup(true)}>
                                                    Skip Commodity Codes and Tags
                                                </DSLink>
                                            </span>
                                        )}
                                        <DSButton type='primary' onClick={confirmContinue}>
                                            Continue
                                        </DSButton>
                                    </CommodityCodesSpacedButtonWrapperMobile>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            }
            <ModalPopUp
                size='lg'
                title='Are you sure?'
                closeModal={() => toggleConfirmationPopup(false)}
                isOpen={confirmationPopup === true}
                backdrop='static'
            >
                <p>
                    To get notifications about bids that may be a good fit for your business, you must choose
                    commodity codes. Adding codes is free!
                </p>
                <div className='pt-3 mt-3'>
                    <PopUpFlexContainerMobile justifyContent='space-between'>
                        <DSButton type='secondary' onClick={confirmSkip}>
                            Skip adding commodity codes and Tags
                        </DSButton>
                        <span className='bttn-continue'>
                            <DSButton type='primary' onClick={() => toggleConfirmationPopup(false)}>
                                Add commodity codes and Tags
                            </DSButton>
                        </span>
                    </PopUpFlexContainerMobile>
                </div>
            </ModalPopUp>


        </>
    );
};
