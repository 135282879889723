import { useCallback, useEffect, useState } from 'react';

import { Flex, FlexContainer, H2, Large } from '@demandstar/components/styles';
import { DSAlert } from '@demandstar/components/alert';
import { DSButton } from '@demandstar/components/button';
import { DSSearch } from '@demandstar/components/search';
import { smoothScrollTo } from '@demandstar/components/utils';

import * as texts from './ContractReminders.texts';
import { Feature, FeatureFlagKey } from 'src/components/common/feature-flag';
import { AddReminder } from './AddReminder';
import { Alert } from 'src/components/common/alert';
import { CMTabNavigation } from '../../navigation/CMTabNavigation';
import { ContractReminder } from './hook/reminders';
import { Reminders } from './Reminders';
import { Status } from '@demandstar/components/constants';
import { useBreadcrumbs } from 'src/components/common/breadcrumb';
import { useContractDetails } from '../../useContractDetails';
import { useReminders } from './hook/useReminders';
import { useReminderSearch } from './hook/useReminderSearch';
import { useRouter } from 'src/shared/hooks';
import { hasPermission, hasValue } from 'src/utils/helpers';
import { useDSSelector } from 'src/store/reducers';
import { userPermissionKeys } from 'src/utils/constants';
import history from 'src/utils/history';
import { useAuthContext } from 'src/auth/AuthProvider';
import { Authorization } from 'src/types';

export function ContractReminders() {
  const { searchParams } = useRouter();
  const { reminderAlertId, reminders, remindersLoadable } = useReminders();
  const { defaultSort, filters, search } = useReminderSearch();
  const urlAddingReminder = searchParams.get('add') === 'true';
  const [addingNewReminder, setAddingNewReminder] = useState(urlAddingReminder);
  const { setBreadcrumbInfo } = useBreadcrumbs();
  const { contractDetails } = useContractDetails();

  const { auth } = useAuthContext();
  const memberinfo = useDSSelector(state => state.memberinfo);

  const { prms } = memberinfo;
  // TODO: This logic is repeated in several components, should be abstracted into a utility or hook
  const { accountPermissions: authPerms, mainContact: isPrimaryContact = false } =
    auth || ({} as Authorization);
  const accountPermissions = prms ? prms : authPerms;

  const hasManageBidsPermission =
    hasPermission(accountPermissions, userPermissionKeys.manageBids) || isPrimaryContact;
  useEffect(() => {
    if (hasManageBidsPermission === false) {
      history.push('/buyers/dashboard');
    }
  }, [hasManageBidsPermission]);

  const toggleAddingNewReminder = useCallback(() => {
    setAddingNewReminder(newReminder => !newReminder);
  }, []);

  const renderResultSet = useCallback(
    ({ data }: { data: ContractReminder[] }) => <Reminders reminders={data} />,
    [],
  );

  useEffect(() => {
    setBreadcrumbInfo({
      activePageLabel: contractDetails?.id
        ? contractDetails?.solicitationId || contractDetails?.name
        : 'Reminders',
    });
  }, [contractDetails, setBreadcrumbInfo]);

  useEffect(() => {
    if (urlAddingReminder) {
      smoothScrollTo('#add-reminder-header');
    }
  }, [urlAddingReminder]);

  return (
    <>
      <Feature flag={FeatureFlagKey.ContractManagement} redirect={'/'} />
      <CMTabNavigation />
      <FlexContainer>
        <Flex>
          <H2 data-testid='contract-reminders-header'>{texts.reminders}</H2>
          <Large marginBottom>{texts.remindersDescription}</Large>
        </Flex>
        <Flex grow={0}>
          <DSButton onClick={toggleAddingNewReminder}>{texts.createReminder}</DSButton>
        </Flex>
      </FlexContainer>

      {addingNewReminder && <AddReminder onCreate={toggleAddingNewReminder} />}
      <Alert id={reminderAlertId} allowDismiss />
      {!!reminders.length && (
        <DSSearch
          emptyMessage={<DSAlert header={texts.noReminders} type={Status.Success} />}
          filters={filters}
          recordKey={'id'}
          renderResultSet={renderResultSet}
          search={search}
          sort={{ defaultSort }}
        />
      )}
      {hasValue(remindersLoadable) && !reminders.length && (
        <DSAlert header={texts.noReminders} type={Status.Success} />
      )}
    </>
  );
}
