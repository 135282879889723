import { useCallback, useEffect } from 'react';

import AddressReduxForm from '../../../common/addressreduxform';
import OperationFunctionsForm from './operationfunctionsform';

import { accountMemberInfo, memberinfoParamType } from '../../../../types/bids';
import { Buttons, NoResult } from '../../../customcontrols';
import { formattedPhoneNumber, getWebsiteFn } from '../../../../utils/helpers';

import { loadingMsg } from '../../../../utils/texts';
import { MemberType } from '../../../../utils/constants';
import { sharedTypes } from '../../../../types/shared';
import { useAuthContext } from 'src/auth/AuthProvider';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface MemberInfoTabProps {
  accountinfo?: {
    planholderTypes: Array<Record<string, unknown>>;
    membersinfo: Array<Record<string, unknown>>;
    accountinfo: {
      memberInfo: accountMemberInfo[];
    };
  };
  updateMemberInfo?: any;
  GoBackToPage: () => void;
  isEdit: boolean;
  EditMemberInfo: () => void;
  memberinfo?: memberinfoParamType;
  hasEditMemberPermission: string | boolean;
  shared?: sharedTypes;
  setAccountInfoDetails?: any;
  getplanholderTypes?: any;
  getTimezoneList?: any;
  form?: {
    addressreduxForm:
      | {
          values?:
            | {
                country?: { value?: number | undefined } | undefined;
                state?: { value?: number | undefined } | undefined;
              }
            | undefined;
        }
      | undefined;
  };
}

export const MemberInfoTab = (props: MemberInfoTabProps) => {
  const {
    accountinfo,
    updateMemberInfo,
    GoBackToPage,
    isEdit,
    hasEditMemberPermission,
    EditMemberInfo,
    memberinfo,
    shared,
    setAccountInfoDetails,
    getplanholderTypes,
    getTimezoneList,
  } = props;
  const { auth } = useAuthContext();

  const { memberInfo = [] } = (accountinfo && accountinfo.accountinfo) || {};

  let memberAddressInfo =
    memberInfo.find((item: accountMemberInfo) => item.addressType === 'MA') ||
    ({} as accountMemberInfo);
  if (!memberAddressInfo.shortName)
    memberAddressInfo =
      memberInfo.find((item: accountMemberInfo) => item.addressType === 'BA') ||
      ({} as accountMemberInfo);

  // TODO: replace redux state with auth/accountInfo hooks
  // const { memberInfo = [] } = accountInfo ;

  // let memberAddressInfo =
  //   memberInfo.find((address) => address.addressType === 'MA') ||
  //   ({} as accountMemberInfo);
  // if (!memberAddressInfo.shortName)
  //   memberAddressInfo =
  //     memberInfo.find((item) => item.addressType === 'BA') ||
  //     ({} as accountMemberInfo);

  const {
    shortName,
    address1,
    address2,
    city,
    postalCode,
    phoneNumber,
    stateName,
    countyName,
    countryId,
    website,
  } = memberAddressInfo || {};

  const {
    mi: memberId,
    ms: status,
    dnd: doNotNotify,
    phdt: planholderDisplay,
    ifd: isFreeDocument,
    isImportAvailable:IsImportAvailable,
    tzi: timeZone,
    isExternalAgency,
  } = memberinfo || {};
  const { planholderTypes = [] } = accountinfo || {};
  const { countrieslist = [], timeZoneList = [] } = shared || {};
  const filteredCountries =
    countrieslist.length > 0 &&
    (countrieslist.filter((v: { value: number }, i: number) => v.value === countryId) || []);
  const [countryDetails = {}] = filteredCountries || [];
  const { label: country } = countryDetails;

  const componentAcc =
    !isEdit && hasEditMemberPermission ? (
      <li title='Edit'>
        <span className='staticLink' onClick={EditMemberInfo}>
          <i className='mdi mdi-square-edit-outline mr-1' /> Edit
        </span>
      </li>
    ) : (
      ''
    );

  useSetBreadcrumb(
    {
      page: 'Account Info',
      title: 'Member Information',
      altname: 'Member Information',
      name: 'Member Information',
      menuactive: '',
      component: componentAcc,
    },
    !isEdit,
  );

  const handleMemberInfoSubmit = useCallback(
    (formData = {}) => {
      updateMemberInfo({ formData: { ...formData, addressType: 'MA' }, auth });
      GoBackToPage();
      setAccountInfoDetails({ reloadABillingInfo: true });
    },
    [auth, updateMemberInfo, GoBackToPage, setAccountInfoDetails],
  );

  useEffect(() => {
    if (auth?.memberType === MemberType.AgencyBuyer) getplanholderTypes();
  }, [auth, getplanholderTypes]);

  useEffect(() => {
    getTimezoneList();
  }, [getTimezoneList]);

  const initialValues = {
    status,
    doNotNotify: doNotNotify,
    planholderDisplay: planholderDisplay
      ? planholderTypes.find((item: any) => item.planholderDisplayType === planholderDisplay)
      : '',
    isFreeDocument: isFreeDocument,
    IsImportAvailable:IsImportAvailable,
    timeZoneId: timeZone
      ? timeZoneList.find((item: any) => parseInt(item.timeZoneId) === parseInt(timeZone))
      : '',
  };

  return (
    <>
      {isEdit ? (
        <>
          {auth?.opsId ? (
            <>
              <h4 data-testid='accountinfo.contactinfo.ops.edit.head'>Operations Functions</h4>
              <div className='row'>
                <div className='col-md-5'>
                  <div className='innerColIndent'>
                    <OperationFunctionsForm
                      {...props}
                      initialValues={initialValues}
                      accountinfo={accountinfo}
                      isExternalAgency={isExternalAgency}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <h4 data-testid='accountinfo.contactinfo.edit.head'>Contact Information</h4>
          <div className='row'>
            <div className='col-md-8 col-lg-6'>
              <div className='innerColIndent'>
                <AddressReduxForm
                  {...props}
                  initialValues={accountinfo && accountinfo.membersinfo}
                  onSubmit={handleMemberInfoSubmit}
                  pagevaluespagefor='actioninfo-memberinfo'
                  pagevaluescancel={GoBackToPage} // memoized with useCallback
                  pagevaluescancelname='Cancel'
                  pagevaluessavename='Save'
                  pagevaluesbreadcrumb=''
                  pagevaluespage='Account Info'
                  pagevaluestitle='Member Information'
                  pagevaluesaltname='Member Information'
                  pagevaluesname='Member Information'
                  pageinfo='editmemberinfo'
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            <div className='col-md-5'>
              <h3 className='arrowWrapper d-inline-block'>Member Information</h3>
              <h4 data-testid='accountinfo.memberinfo.head'>Member Information</h4>
              <div className='innerColIndent'>
                <h6 data-testid='accountinfo.memberinfo.memberid'>Member ID</h6>
                <div data-testid='accountinfo.memberinfo.memberid.value'>
                  {memberId ? memberId : <NoResult message={loadingMsg} />}
                </div>
                <h6 data-testid='accountinfo.memberinfo.address'>Address</h6>
                {memberId && shortName ? (
                  <p data-testid='accountinfo.memberinfo.address.value'>
                    {shortName}
                    <br />
                    {address1}
                    {address1 && address2 ? ',' : ' '} {address2} <br />
                    {city} {countyName ? `, ${countyName}` : ''}
                    {stateName ? `, ${stateName}` : ''}
                    {country ? `, ${country}` : ''}
                    {postalCode ? `, ${postalCode}` : ''}
                    <br />
                    {phoneNumber ? `Phone : ${formattedPhoneNumber(phoneNumber)}` : null}
                    <br />
                    {website ? (
                      <>
                        Website :{' '}
                        <a href={getWebsiteFn(website)} target='_blank' rel='noopener noreferrer'>
                          {website}
                        </a>
                      </>
                    ) : null}
                  </p>
                ) : (
                  <NoResult message={loadingMsg} />
                )}
              </div>
              {hasEditMemberPermission ? (
                <Buttons
                  classNames='bttn-primary my-4 float-right'
                  text='Edit'
                  title='Edit'
                  type='button'
                  onClick={EditMemberInfo}
                  data-testid='accountinfo.memberinfo.editbtn'
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};
