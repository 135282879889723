import { useQuery } from '@tanstack/react-query';
import { getCommodities } from 'src/store/services';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';
import { track } from '../../../utils/telemetry';

const GetCommodityCodes = (data:any) => {
  return getCommodities(data);
};

export const useGetCommodityCodes = (data:any) => {
  return useQuery({
    queryKey: ['CommodityCodes'],
    queryFn: async () => {
      try {
        return await GetCommodityCodes(data);
      } catch (error) {
        track('GetCommodityCodes -- getommoditiyCodes.ts -- ERROR:', { error });
        throw error;
      }
    },
    staleTime: STALE_TIMES.ONE_DAY,
  });
};
