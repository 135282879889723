
//PROD
export const PROD_AMPLITUDE_API = '53320efd760ea246efbbd5d2b922c998';
export const PROD_APP_INSIGHTS= 'c9493017-35ce-42e0-b806-d0144da2e8a6';
export const PROD_COGNITIVE_SEARCH_BID_TAGS_API = '7gxFOpM2xM0Zh6dHsKsUmnEsEVWX5trpSYl4ZP3IFAAzSeCuA5Tw';
export const PROD_COGNITIVE_SEARCH_COMMODITY_CODES_API = '7gxFOpM2xM0Zh6dHsKsUmnEsEVWX5trpSYl4ZP3IFAAzSeCuA5Tw';

//QA
export const QA_AMPLITUDE_API= '3bc541b379836d9f52b35e05ced1d186';
export const QA_COGNITIVE_SEARCH_BID_TAGS_API = 'KQodRdkHhVWUgyuer3BJWQsVtCm1FSUKQyTHrUP3AuAzSeDCgWjG';
export const QA_APP_INSIGHTS= 'c1045fb5-0535-4666-8490-b7d6423bdd04';
export const QA_COGNITIVE_SEARCH_COMMODITY_CODES_API = 'KQodRdkHhVWUgyuer3BJWQsVtCm1FSUKQyTHrUP3AuAzSeDCgWjG';

export const JWTsigningKey = 'secret';
