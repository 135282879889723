import { useEffect, useState } from 'react';

const useScreenWidth = (maxWidth: number): boolean => {
  const [isScreenWidthLessThanMax, setIsScreenWidthLessThanMax] = useState<boolean>(window.innerWidth < maxWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenWidthLessThanMax(window.innerWidth < maxWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [maxWidth]);

  return isScreenWidthLessThanMax;
};

export default useScreenWidth;
