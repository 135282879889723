/* eslint-disable max-lines */
/* TOREFACTOR - split into multiple files. this is > 500 lines */

// NOTE: Do not add new helpers to this file
// Instead, create a page specific file and import it

import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import { emailVal } from '@demandstar/components/utils';

// import * as actionTypes from '../../store/actionTypes';
import { MemberType, sortDirections, toastAutoCloseDelay, urlDateFormat } from '../constants';
import { Authorization, MembershipLevel, Permission } from '../../types/auth';
import { UploadedFile } from '../../types/addbid';

export * from './amplitude';
export * from './array';
export * from './auth';
export * from './awardBid';
export * from './conversions';
export * from './dateTime';
export * from './dateTime_deprecated';
export * from './formatters';
export * from './navigation';
export * from './proration';
export * from './recoil';
export * from './string';
export * from './testing';
export * from './validation';
export * from './wizard';

export interface BidStatusPartial {
  statusType: string;
  statusName: string;
}

interface BidStatus extends BidStatusPartial {
  label: string;
  value: string;
}

interface FiscalYear {
  value: string;
  label: string;
}

type BasicArray = Array<string | number | boolean>;
type EncodableVal = string | number | boolean | FiscalYear | BidStatus | BasicArray | null;
type FilterVal = moment.Moment | EncodableVal;
type Filter = Record<string, FilterVal>;

export const range = (from: number, to: number, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export const getTwoDigit = (number: number) => {
  return (number < 10 ? '0' : '') + number;
};

// To check two object contains variable values are same or not
export const isEquivalent = (a: { [x: string]: any }, b: { [x: string]: any }) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

// To get file extension(.pdf) from input type=file
/* TODO: Can we change this to take a single file instead of an array?
  Usually we're wrapping the file in brackets just so we can pass it in here
  And it only looks at one file anyway. Let's just send that file directly.
*/
export const getFileExtension = (files: UploadedFile[]) => {
  return files?.length ? getExtension(files[0].name) : '';
};

export function getExtension(filePath: string, upperCase = true) {
  const extension = filePath.substring(filePath.lastIndexOf('.') + 1);
  return upperCase ? extension.toUpperCase() : extension;
}

export const hasPermission = (permissions: string, permissionId: number) => {
  return (
    permissions && permissions.replace(/\s/g, '').split(',').map(Number).includes(permissionId)
  );
};

export const canShowPrograms = (memberType: string) => {
  return memberType === 'SS';
};

export const canShowSelfDeclarations = (memberType: string) => {
  return memberType === 'SS';
};

export const isPrimaryAccount = (memberAccounts: any[], currentAccountId: any) => {
  return (
    memberAccounts &&
    memberAccounts.length > 0 &&
    memberAccounts.find(account => account.accountId === currentAccountId).mainContact
  );
};

// To Display React Toastify
export const toastFn = (reason: any = '', message?: string, id?: any, autoClose = false) => {
  if (!toast.isActive(id)) {
    if (reason) {
      (toast as any)[reason](message, {
        toastId: id,
        autoClose: autoClose ? autoClose : toastAutoCloseDelay,
      });
    } else {
      toast(message, {
        toastId: id,
        autoClose: autoClose ? autoClose : toastAutoCloseDelay,
      } as any);
    }
  }
};

export const dismisstoastAllFn = () => toast.dismiss();

export const getReferrer = () => {
  if (document.referrer) {
    const anchor = document.createElement('a');
    anchor.href = document.referrer;
    return anchor.hostname;
  }
  return '';
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (cname: string, value: any, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; domain=${
      window.location.hostname === 'localhost' ? '' : '.demandstar.com'
    }; expires=${date.toUTCString()}`;
  }
  document.cookie =
    cname + '=' + (value || '') + expires + '; Secure' + '; SameSite=Strict' /*+ '; HttpOnly' */ + '; path=/' ;
};

// To Sort List
export const SortingFn = (list: any[] = [], order: sortDirections, value = '', type = 'text') => {
  let sortedlist = [];
  if (!value) {
    return list;
  }
   const primaryList = [...list];
  switch (order) {
    case sortDirections.DESC:
      if (type === 'text')
        sortedlist = primaryList.sort((a, b) => {
          const aValue = a[value] ? a[value].toString() : '';
          const bValue = b[value] ? b[value].toString() : '';
          return bValue.localeCompare(aValue.toString());
        });
      else sortedlist = primaryList.sort((a, b) => b[value] && b[value] - a[value]);
      break;
    case sortDirections.ASC:
      if (type === 'text')
        sortedlist = primaryList.sort((a: any, b: any) => {
          const aValue = a[value] ? a[value].toString() : '';
          const bValue = b[value] ? b[value].toString() : '';
          return aValue.localeCompare(bValue);
        });
      else sortedlist = primaryList.sort((a, b) => a[value] && a[value] - b[value]);
      break;
    default:
      sortedlist = primaryList;
  }
  return sortedlist;
};

// To Search

// TODO: Rename this. Proposal: search?
export const Searchingfn = (list: any[] = [], searchname: any[] = [], value: any = '') => {
  let searchlist: any[] = [];
  searchlist = searchname.map(items =>
    list.filter(
      (item: any) =>
        item[items] &&
        item[items]
          .toLowerCase()
          .trimStart()
          .trimEnd()
          .search(value && value.toLowerCase().trimStart().trimEnd()) !== -1,
    ),
  );
  // searchlist = list.filter(item => item['name'].toLowerCase().search(value.toLowerCase()) !== -1)
  return [...Array.from(new Set(searchlist.flat()))];
};

// To Group Search (TODO: Rename to groupSearch)
export const GroupSearchingfn = (list: any[] = [], searchname: any[] = [], value: any[] = []) => {
  let searchlist = list;
  value.forEach((items: any, index) => {
    if (items) {
      return (searchlist = searchlist.filter(
        (item: any) =>
          item[searchname[index]]
            .toLowerCase()
            .trimStart()
            .trimEnd()
            .search(items.toLowerCase().trimStart().trimEnd()) !== -1,
      ));
    } else {
      return searchlist;
    }
  });

  return [...Array.from(new Set(searchlist.flat()))];
};

export const generateKey = (pre: string) => {
  return `${pre}_${new Date().getTime()}`;
};

export const sumObjectFields = (array: any[], key: string) => {
  return array.reduce((r, a) => {
    return r + a[key];
  }, 0);
};

// array to object
export const convertArrayToObject = (
  array: any[],
  key: string | number,
  value: string | number,
) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[value],
    };
  }, initialValue);
};

// Show Bids Selection

export const getShowBidsFn = (auth: Authorization) => {
  const levels =
    auth?.membershipLevels &&
    (auth?.membershipLevels.replace(' ', '').split(',') as MembershipLevel[]);
  const permissions =
    auth?.accountPermissions &&
    (auth?.accountPermissions.replace(' ', '').split(',') as Permission[]);

  const IsOps = permissions.includes(Permission.Ops);
  const IsBidPoster = levels.includes(MembershipLevel.BidPoster);
  const IsBidResponder = levels.includes(MembershipLevel.BidResponder);
  const IsPinned = false;

  const KeyAll = 'All';
  const KeyMine = 'Mine';
  const KeyNotified = 'Notified';
  const KeyOrdered = 'Ordered';
  const KeyEBids = 'EBids';
  const ValueAll = 'All bids in the system';
  const ValueMine = 'Only Show My Bids';
  const ValueNotified = 'Bids for which I have been notified';
  const ValueOrdered = 'Bids I have ordered';
  const ValueEBids = 'eBids only';

  let showBidsResult = [];

  if (IsOps && !IsPinned) {
    showBidsResult = [{ value: KeyAll, label: ValueAll, DefaultSelected: true }];
  } else {
    if (IsBidPoster) {
      showBidsResult = [
        { value: KeyMine, label: ValueMine, DefaultSelected: true },
        { value: KeyAll, label: ValueAll },
      ];
    } else {
      if (auth?.memberStatus === 'EP') {
        showBidsResult = [
          { value: KeyNotified, label: ValueNotified, DefaultSelected: true },
          { value: KeyOrdered, label: ValueOrdered },
        ];
      } else if (IsBidResponder) {
        showBidsResult = [
          { value: KeyNotified, label: ValueNotified, DefaultSelected: true },
          { value: KeyOrdered, label: ValueOrdered },
          { value: KeyAll, label: ValueAll },
        ];
      } else {
        showBidsResult = [{ value: KeyAll, label: ValueAll, DefaultSelected: true }];
      }
    }
  }
  if (IsOps) {
    showBidsResult = [{ value: KeyEBids, label: ValueEBids }];
  }

  // TODO: have to remove below lines when we go for live
  //(!showBidsResult.Any(showBid => showBid.Key == Constants.ShowBids.KeyEBids))
  const checkEbids = showBidsResult.filter((item: any) => item.key === KeyEBids) || [];
  if (checkEbids.length === 0) {
    showBidsResult.push({ value: KeyEBids, label: ValueEBids });
  }

  return showBidsResult || [];
};

export const getKeyofObject = (object: any, value: any) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const formattedPhoneNumber = (phoneNumber = '', extension = '') => {
  let value = '';
  if (phoneNumber && phoneNumber.length > 6) {
    value = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(
      6,
    )}`;
    if (extension) value = `(${extension}) ${value}`;
  }
  return value;
};

export const checkPaginationCallFn = (
  currentpage: number,
  listPerPages: number,
  list: string | any[],
  total: any,
) => {
  const nextindexOfLastLists = currentpage * listPerPages;
  const nextindexOfFirstLists = nextindexOfLastLists - listPerPages;
  const nextlist = list.slice(nextindexOfFirstLists + 50, nextindexOfLastLists + 50);
  return nextlist.length !== listPerPages && list.length !== total;
};

export const checkFormatted = (dateString: string) => {
  const dateSplit = dateString.split('/');
  if (dateSplit.length === 3) {
    if (dateSplit[0].length === 1) return true;
    if (dateSplit[1].length === 1) return true;
  }
  return false;
};

export const addDsEvent = (node: any, eventName: string, fn: (event?: any) => any): void => {
  if (node.addEventListener) {
    node.addEventListener(eventName, fn, false);
  } else if (node.attachEvent) {
    node.attachEvent('on' + eventName, fn);
  }
};

export const IsIEFn = () => {
  const windowdocument = window.document as any;
  return windowdocument?.documentMode;
};

export const getWebsiteFn = (link: string) => {
  let value = '';
  if (link) {
    if (!link.toLowerCase().includes('http')) {
      value = `http://${link}`;
    } else {
      return link;
    }
  }
  return value;
};

export const scrollLeft = (element: any, change: number, duration: number) => {
  let currentTime = 0;
  const start = element.scrollLeft;
  const increment = 20;

  const animateScroll = function () {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollLeft = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

//t = current time
//b = start value
//c = change in value
//d = duration
const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const sortProductFn = (a: any, b: any) => a.productName.localeCompare(b.productName);

export const filterDistinctArray = (inArray: any, fieldName: string) => {
  const newArray = [] as any;
  const distinctArray = [] as any;
  inArray.map((item: any) => {
    if (!newArray[item[fieldName]]) {
      newArray[item[fieldName]] = item;
    }
  });
  for (const i in newArray) {
    distinctArray.push(newArray[i]);
  }
  return distinctArray;
};

export function encodeFilters(filters: Filter) {
  return Object.keys(filters)
    .filter(key => {
      // showBids and showBidReset are filters we derive from user-set filters
      // so let's hide them from the url
      if (key === 'showBids' || key === 'showBidReset') return false;
      //filter empties so we don't encode an empty array
      // and insert into url
      const value = filters[key];
      if (Array.isArray(value)) {
        return value.length;
      }
      return value;
    })
    .reduce((acc, key) => {
      const value: FilterVal = filters[key];
      if (isMoment(value)) {
        acc.append(key, value.format(urlDateFormat));
      } else if (isBasicArray(value)) {
        value.forEach(v => acc.append(key, JSON.stringify(v)));
      } else if (isBidStatus(value) || isFiscalYear(value)) {
        acc.append(key, value.value);
      } else {
        acc.append(key, `${value}`);
      }
      return acc;
    }, new URLSearchParams(''))
    .toString();
}

// User-defined type guard. Helps encodeHelper
// know val is NOT a moment in the else-clause of
// it's condition, so the compiler is satisfied
function isMoment(x: FilterVal): x is moment.Moment {
  return moment.isMoment(x);
}

function isBasicArray(value: FilterVal): value is BasicArray {
  if (Array.isArray(value)) {
    if (value.length === 0) return true;
    const [first] = value;
    return ['number', 'string', 'boolean'].includes(typeof first);
  }
  return false;
}

function isBidStatus(value: FilterVal): value is BidStatus {
  if (!value) return false;
  return (
    (value as BidStatus).statusType !== undefined && (value as BidStatus).statusName !== undefined
  );
}

function isFiscalYear(value: FilterVal): value is FiscalYear {
  if (!value) return false;
  return (value as FiscalYear).label !== undefined && (value as FiscalYear).value !== undefined;
}

export function decodeParams(search: string, bidStatuses: BidStatusPartial[]): Filter {
  const params = new URLSearchParams(search);
  return Array.from(params.keys()).reduce((acc: Filter, key: string) => {
    if (key === 'industry') {
      acc[key] = decodeArray(params.getAll(key));
    } else if (key === 'states') {
      const statesArr = decodeArray(params.getAll(key)) || [];
      acc[key] = Array.isArray(statesArr) ? statesArr.slice(0, 3) : null;
    } else {
      acc[key] = decodeString(key, params.get(key), bidStatuses);
    }
    return acc;
  }, {});
}

function decodeArray(values: string[]): FilterVal {
  if (!values.length) return null;
  return values.map(value => Number(value));
}

function decodeString(
  key: string,
  value: string | null,
  bidStatuses: BidStatusPartial[],
): FilterVal {
  if (!value) return null;
  switch (key) {
    case 'endDueDate':
    case 'startDueDate':
      return moment(value, urlDateFormat);
    case 'bidStatus':
      const status = bidStatuses.find(status => status.statusType === value);
      if (status) {
        return {
          statusType: value,
          value: value,
          label: status.statusName,
          statusName: status.statusName,
        };
      } else {
        return null;
      }
    case 'fiscalYear':
      return { value: value, label: value };
    case 'location':
    case 'agencyMemberId':
      return Number(value);
    default:
      return value;
  }
}

export const addUpdateMetaTag = (key: string, value: string) => {
  const heads = document.getElementsByTagName('head');
  if (heads.length > 0) {
    const metas: HTMLMetaElement[] = [].filter.call(
      heads[0].getElementsByTagName('meta'),
      (el: HTMLMetaElement) => el.name === key,
    );
    if (metas.length > 0) {
      metas[0].content = value;
    } else {
      const meta = document.createElement('meta');
      meta.name = key;
      meta.content = value;
      heads[0].appendChild(meta);
    }
  }
};

/** @deprecated - consider using DSEmailField instead. */
export const emailValidation = (email: string) => {
  return email && !emailVal.test(email.toLowerCase().trimStart().trimEnd());
};

export const getSubscribedProductWithRespectivePrice = (
  allProducts: any,
  currentSubscription: any,
  existingSubscription: any,
  isCheckOldPrice = true,
) => {
  const selectedSubscription = allProducts
    .filter((item: any) => currentSubscription.includes(item.productId))
    .map((sItem: any) => {
      const priceData: any = existingSubscription.find(
        (eItem: any) => eItem.productId === sItem.productId,
      );

      return {
        ...sItem,
        price: priceData && isCheckOldPrice ? priceData.price : sItem.price,
      };
    });
  return selectedSubscription;
};

export const openHelpPage = (memberType: string) => {
  if (memberType === MemberType.AgencyBuyer)
    window.open('https://network.demandstar.com/agency-support/', '_blank');
  else window.open('https://network.demandstar.com/supplier-support/', '_blank');
};

export const guidValidation = (guid: string) => {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return pattern.test(guid);
};

export const getOptimizedCommodityCodes = (commoditieslist: any) => {
  const generatenewlist = commoditieslist.filter(
    (item: { isSelected: boolean }) => item.isSelected,
  );

  const mainCategories = generatenewlist
    .filter((item: any) => {
      if (item.commodityCode === '00') {
        const allCodes = commoditieslist
          .filter(
            (items: { commodityCategory: string | number; commodityCode: string }) =>
              items.commodityCategory === item.commodityCategory && items.commodityCode !== '00',
          )
          .map((items: { commodityId: number | string }) => items.commodityId);

        const selectedCodes = generatenewlist
          .filter(
            (items: { commodityCategory: string | number; commodityCode: string }) =>
              items.commodityCategory === item.commodityCategory && items.commodityCode !== '00',
          )
          .map((items: { commodityId: number | string }) => items.commodityId);

        if (allCodes.length === selectedCodes.length) {
          return true;
        }
        return false;
      }
      return false;
    })
    .map((items: { commodityCategory: string | number; commodityId: string | number }) => ({
      commodityCategory: items.commodityCategory,
      commodityId: items.commodityId,
    }));

  const categoryIds = mainCategories.map(
    (item: { commodityCategory: string | number }) => item.commodityCategory,
  );
  const mainCategoryIds = mainCategories.map(
    (item: { commodityId: string | number }) => item.commodityId,
  );
  const finalList = generatenewlist
    .filter(
      (items: any) =>
        !categoryIds.includes(items.commodityCategory) && items.commodityCode !== '00',
    )
    .map((items: any) => items.commodityId);
  const optimizedCommodityCodes = [...mainCategoryIds, ...finalList];
  return optimizedCommodityCodes;
};

const updateRobotsMetaTag = (value: string) => {
  const robotsMeta = document.getElementById('robotsMeta') as HTMLMetaElement;
  if (robotsMeta) {
    robotsMeta.name = 'robots';
    robotsMeta.content = value;
  }
};

export const allowSEOIndex = () => {
  updateRobotsMetaTag('all');
};

export const disAllowSEOIndex = () => {
  updateRobotsMetaTag('none');
};

/*************************************************
 * End of deprecated functions
 * Do not add additional functions below.
 * Add all new functions to an existing helper page or add a new one.
 ************************************************/

/*************************************************
 * For real, please do not add more functions to this file.
 * Please do not approve PRs that add more functions to this file.
 ************************************************/
