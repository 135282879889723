import { Controller,FieldError,get, RegisterOptions, useForm, useFormContext } from 'react-hook-form';
import { InputHTMLAttributes } from 'react';

import { emailMaxLength, emailVal, emailValErrorMsg } from '../../utils';
import { FieldContainer, FieldProps, getFieldContainerProps } from '../../field-container';
import { BaseTextInput } from '../../inputs';
import { Inactive } from 'src/types/shared';
import { RelativeContainer } from '../../assets/CommonStyles';

export type DSEmailFieldProps = FieldProps &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'autoFocus' | 'placeholder'> &
  Inactive & {
    /** HTML name (must be unique) */
    name: string;
    /** react-testing-library test id */
    dataTestId?: string;
    /** HTML label. Will also add additional margin at bottom. */
    label?: string;
    /** additional description */
    message?: string;
    /** Rules in the format of RHF `RegisterOptions` */
    rules?: RegisterOptions;
    /** default value for input */
    value?: string;
  };

/** Email field with built in validation, exclusively used with react-hook-form
 * @example <DSEmailField name='email' label='Email' />
 * */
export const DSEmailField = (props: DSEmailFieldProps) => {
  const { autoFocus, inactive, name, placeholder, dataTestId, optional, rules, value = '' } = props;
  const methods = useFormContext() as any;

  const { control, formState: { errors } } = methods;
  const error = errors[name] as FieldError;

  const registerOptions: any = {
    required: !optional,
    pattern: {
      value: emailVal,
      message: emailValErrorMsg,
    },
    maxLength: emailMaxLength,
    ...rules,
  };

  return (
    <Controller
      control={control}
      defaultValue={value}
      name={name}
      rules={registerOptions}
      render={({ field }) => (
        <FieldContainer {...getFieldContainerProps(props, error)}>
          <RelativeContainer>
            <BaseTextInput
              autoFocus={autoFocus}
              data-testid={dataTestId || name}
              error={error}
              id={name}
              {...field}
              placeholder={placeholder}
              readOnly={inactive}
              value={field.value}
            />
          </RelativeContainer>
        </FieldContainer>
      )}

    />
  );
};
