import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { BidExternalStatusType, DeprecatedFullTimeZone, MemberType } from '../../utils/constants';
import { anyParamType } from '../../types/paymentdetails';
import { displayDate } from '../../utils/helpers';
import { extendedNetworkText } from '../../utils/constants';
import { incompleteconditionslistParamType } from '../../types/bids';
import { Incompletemodal } from '../shared/bids/incompletemodal';
import { ModalPopUp } from './modals/ModalPopUp';
import { AppContext } from 'src/global-context/AppContext';

export const ListItem = ({ label, value }: { label: string; value: any }) => {
  const list =
    label && label ? (
      <li className='list-inline-item'>
        <i>{label}:</i> {value}
      </li>
    ) : (
      <li className='list-inline-item'>{value}</li>
    );

  return list;
};

interface BidsListProps {
  agency?: string;
  bidId?: number;
  bidIdentifier?: string;
  bidName?: string;
  planholders?: number;
  broadCastDate?: Date;
  dueDate?: Date;
  status?: string;
  watches?: number;
  isExternalBid?: boolean;
  watchStatus?: string;
  city?: string;
  county?: string;
  state?: string;
  statusTheme?: string;
  memberType?: string;
  pagefor?: string;
  percentage?: string;
  area?: string;
  incompletebidid?: string;
  statusType?: string;
  setBidDetails?: anyParamType;
  canComplete?: boolean;
  onWatchClick?: any;
  getConditionspending?: any;
  incompleteconditionslist?: incompleteconditionslistParamType[];
  tzfn?: DeprecatedFullTimeZone | '';
  planholdersCount?: number;
  watchersCount?: number;
}

export const BidsList = (props: BidsListProps) => {
  const {
    agency,
    bidId = 0,
    bidIdentifier,
    isExternalBid,
    bidName,
    planholders,
    broadCastDate,
    dueDate,
    status,
    watches,
    watchStatus,
    city,
    county,
    state,
    statusTheme,
    memberType,
    pagefor = 'search',
    incompletebidid = '',
    statusType,
    setBidDetails,
    tzfn,
    planholdersCount,
    watchersCount,
  } = props;
  const [incompletemodal, setIncompletemodal] = useState(false);
  const { RefetchDashboardTrackingData } = useContext(AppContext);

  const onWatchClick = (bidId: number, isWatch: boolean) => {
    props.onWatchClick({ bidId, watchOn: isWatch });
    RefetchDashboardTrackingData(true);
  };

  const agencyInfo = `${agency}, ${city}, ${county ? county + ', ' : ''}${state}`;

  const handleIncompleteModal = (id = '' as string | number) => {
    setBidDetails({ incompletebidid: id });
    setIncompletemodal(!incompletemodal);
  };

  let statusBarTheme = '' as string;

  if (statusTheme === 'RED' || statusType === BidExternalStatusType.Upcoming) {
    statusBarTheme = 'draft';
  } else if (statusType === BidExternalStatusType.Cancelled) {
    statusBarTheme = 'cancelledbg';
  } else if (statusType === BidExternalStatusType.Awarded) {
    statusBarTheme = 'awardedbg';
  }

  return (
    <>
      <div className='listGroupWrapper clearfix'>
        <h5 className='mw-100 text-truncate' title={bidName}>
          <Link
            className='w-75 text-truncate float-left'
            to={
              `/${memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'}/bids/` +
              bidId +
              '/details'
            }
          >
            {bidName}
          </Link>
          <span className={statusBarTheme}>{status}</span>
          {memberType === MemberType.AgencyBuyer || pagefor === 'similarbids' ? null : (
            <i
            data-testid="watchIcon"
              title={watchStatus ? 'Unwatch this bid' : 'Watch this bid'}
              className={
                'mdi float-right ' + (watchStatus ? 'mdi-eye-off-outline' : 'mdi-eye-plus-outline')
              }
              onClick={() => onWatchClick(bidId, !watchStatus)}
            />
          )}
          {memberType === MemberType.AgencyBuyer ? (
            <>
              {status === 'Upcoming (Bid Not Posted)' ? (
                <i
                data-testid="bidList-alert"
                  title='alert'
                  className={
                    'mdi mdi-18px float-right cursorPointer upcomingAlert ' +
                    (watchStatus
                      ? 'mdi-alert-circle text-yellow300'
                      : 'mdi-alert-circle text-red200')
                  }
                  onClick={() => handleIncompleteModal(bidId)}
                />
              ) : (
                <i />
              )}
            </>
          ) : null}
        </h5>
        <ul className='list-inline d-flex justify-content-start'>
          <p>{agencyInfo}</p>
          {isExternalBid === true ? <p className='pl-1 staticLink'>{extendedNetworkText}</p> : null}
        </ul>
        <ul className='list-inline'>
          <ListItem label='ID' value={bidIdentifier ? bidIdentifier : ''} />
          <ListItem
            label='Broadcast'
            value={broadCastDate && tzfn ? displayDate(broadCastDate, tzfn) : null}
          />
          <ListItem label='Due' value={dueDate && tzfn ? displayDate(dueDate, tzfn) : null} />
          {pagefor === 'similarbids' ? null : (
            <ListItem label='Planholders' value={planholdersCount} />
          )}
          {pagefor === 'similarbids' ? null : <ListItem label='Watchers' value={watchersCount} />}
        </ul>
      </div>
      <ModalPopUp
        title='Missing Conditions for Broadcast'
        size='lg'
        isOpen={incompletemodal}
        closeModal={() => handleIncompleteModal()}
      >
        {incompletemodal && incompletebidid ? (
          <Incompletemodal
            {...props}
            handleOnClick={handleIncompleteModal}
            incompletebidid={incompletebidid}
          />
        ) : (
          ''
        )}
      </ModalPopUp>
    </>
  );
};
