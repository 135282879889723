import { DSCheckbox } from '../../../../../library';
import { NoResult } from '../../../../customcontrols';



export const CognitiveCommodityCodes = ({mergedCodes,checkedCommodities,handleCheckboxChange,formattedExistingCodes}) => {
    return (
        <>
        {mergedCodes?.length > 0 ? (
                        <>
                            <h4 className='mt-2'>Relevant Commodity Codes{' '}({mergedCodes.length})</h4>
                            <div className='commodityBlock'>
                                {mergedCodes
                                    ?.map((commodity: any) => (
                                        <div key={commodity.commodityid}>
                                            <DSCheckbox
                                                name='checkbox'
                                                label={`[${commodity.fullcode}] : ${commodity.commodity} ${!commodity.fullcode.endsWith('00') &&
                                                    checkedCommodities.some(
                                                        item =>
                                                            item.fullcode.endsWith('00') &&
                                                            commodity.fullcode.startsWith(item.fullcode.slice(0, -2)),
                                                    )?'- (Parent Selected)':''}`}
                                                checked={checkedCommodities.some(item =>
                                                    item.commodityid === commodity.commodityid ||
                                                    (item.fullcode.endsWith('00') && commodity.fullcode.startsWith(item.fullcode.slice(0, -2))),
                                                )}
                                                 onClick={() => handleCheckboxChange(commodity)}
                                                 inactive={!commodity.fullcode.endsWith('00') &&
                                                    checkedCommodities.some(
                                                        item =>
                                                            item.fullcode.endsWith('00') &&
                                                            commodity.fullcode.startsWith(item.fullcode.slice(0, -2)),
                                                    )}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </>
                    ) : null}
                    {(checkedCommodities.length > 0 || formattedExistingCodes.length > 0) && (<>
                        <h4 className='mt-3'>
                            Selected Commodity Codes {' '}({checkedCommodities.length})
                        </h4>
                        <small className='text-muted customMargin'>
                            (Commodity codes selected based on your code and tag selections)
                        </small>
                        <div className='commodityBlock '>
                            {checkedCommodities?.filter(commodity => !commodity?.previouslyExistingCode)?.map((commodity: any) => (
                                <div key={commodity.commodityid}>
                                    <DSCheckbox
                                        name='checkbox'
                                        label={`[${commodity.fullcode}] : ${commodity.commodity} ${!commodity.previouslyExistingCode ? `${'-'}(NEW)` : ''}`}
                                        checked={checkedCommodities.some(item => item.commodityid === commodity.commodityid)}
                                        onClick={() => handleCheckboxChange(commodity)}
                                        style={{
                                            fontWeight: !commodity.previouslyExistingCode ? 'normal' : 'bold',
                                            fontStyle: commodity.previouslyExistingCode ? 'normal' : 'italic',
                                        }} />
                                </div>
                            ))}
                            {formattedExistingCodes?.filter(commodity => commodity?.previouslyExistingCode)?.map((commodity: any) => (
                                <div key={commodity.commodityid}>
                                    <DSCheckbox
                                        name='checkbox'
                                        label={`[${commodity.fullcode}] : ${commodity.commodity}`}
                                        checked={checkedCommodities.some(item => item.commodityid === commodity.commodityid)}
                                        onClick={() => handleCheckboxChange(commodity)}
                                        style={{
                                            fontWeight: !commodity.previouslyExistingCode ? 'normal' : 'bold',
                                            fontStyle: commodity.previouslyExistingCode ? 'normal' : 'italic',
                                        }} />
                                </div>
                            ))}
                        </div>
                    </>
                    )}
                    {checkedCommodities.length < 1 && mergedCodes?.length > 0 && formattedExistingCodes.length === 0 &&
                        <>
                            <h4 className='mt-3'>Selected Commodity Codes {' '}({checkedCommodities.length})</h4>
                            <small className='text-muted customMargin '>
                                (Commodity codes selected based on your code and tag selections)
                            </small>
                            <NoResult message={'Choose a tag or a code to see commodity codes based on your selection'} /></>
                    }

        </>
    );
};
