import { useEffect, useState } from 'react';
import { useRecoilStateLoadable } from 'recoil';
import { useDispatch } from 'react-redux';
import CardCount from '../../common/dashboard/cardcount';
import navHistory from '../../../utils/history';
import UpsellCard from './UpsellCard';
import { allProductsState } from '../../../store/recoil/productState';
import { MemberType } from '../../../utils/constants';
import { ProductApiResponse, ProductType } from 'src/types/products';
import { Product as AccountProduct } from 'src/types/subscriptions';
import { UpsellBasic } from './UpsellBasic';
import { useDSSelector } from 'src/store/reducers';
import { useBreadcrumbs } from 'src/components/common/breadcrumb';
import { formatNumber, pluralize } from '../../../utils/helpers';
import {
  getSiblingProducts,
  setSubscriptionDetails,
  trackAmplitudeUserActions,
} from '../../../store/actions';
import { BidApiResponse } from 'src/types/bidssummary';
import { TrackGAEvents } from 'src/utils/telemetry';
import { useAuthContext } from 'src/auth/AuthProvider';

interface Product {
  productId: number;
  productType: string;
  productName: string;
  shortName: string;
}

interface CardData {
  title: string;
  count: number | string;
  classNames: string;
  count0Text: string;
}

function selectPid(products: AccountProduct[] = []): number | null {
  const product = products.find(product => product.productType === ProductType.FreeAgency);
  if (product) {
    return product.productId;
  } else {
    return null;
  }
}

function cardClass(memberType: string, cardIndex: number): string {
  let commonClasses = ['pr-lg-1', 'col-sm-6'];
  const isBasicSupplier = memberType === MemberType.BasicSupplier;
  if (isBasicSupplier && (cardIndex === 0 || cardIndex === 1)) {
    commonClasses = commonClasses.concat(['col-12', 'mb-4', 'col-lg-3', 'mb-lg-0']);
  } else if (cardIndex === 0 || cardIndex === 1) {
    commonClasses = commonClasses.concat(['ncol-12', 'mb-4', 'col-md-6']);
  } else if (isBasicSupplier && cardIndex === 2) {
    commonClasses = commonClasses.concat(['mb-sm-0', 'col-12', 'mb-4', 'col-lg-3', 'mb-lg-0']);
  } else if (cardIndex === 2) {
    commonClasses = commonClasses.concat(['mb-sm-0', 'col-12', 'mb-4', 'mb-lg-0', 'col-md-6']);
  } else if (isBasicSupplier && cardIndex === 3) {
    commonClasses = commonClasses.concat(['col-12', 'col-lg-3']);
  } else {
    commonClasses = commonClasses.concat(['col-12', 'mb-4', 'mb-lg-0', 'col-md-6']);
  }
  return commonClasses.join(' ');
}

function RightsideBottom(props: any) {
  const fallbackAccountInfo: { products: any[] } = { products: [] };
  const dispatch = useDispatch();

  const shared = useDSSelector(state => state.shared);

  const { dashboardnetworkData = {} } = props;

  const { auth } = useAuthContext();
  const memberinfo = useDSSelector(state => state.memberinfo);
  const accountinfo = useDSSelector(state => state.accountinfo);
  const realAccountInfo = useDSSelector(state => state.accountinfo.accountinfo?.accountinfo);

  const agencyMemberId: number | null = useDSSelector(
    state => (state.bidssummary.results as BidApiResponse).memberID || null,
  );
  const agencyName: string | null = useDSSelector(
    state => (state.bidssummary.results as BidApiResponse).agencyName || null,
  );

  const { currentRoute } = useBreadcrumbs();

  const {
    // dashboardnetworkData,
    siblingProducts = [],
    relatedBids = [],
    sibilingParentCounty = '',
  } = shared;
  const { mtn: memberTypeName } = memberinfo;
  const { products: existingProducts = [] } = realAccountInfo || {};
  const {
    nearByGovernments = 0,
    totalBids = 0,
    governments = 0,
    suppliers = 0,
  } = dashboardnetworkData;
  const isFreeAgency = memberTypeName === 'Free Agency';
  const isBasicSupplier = auth?.memberType === MemberType.BasicSupplier;

  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const [parentCounty, setParentCounty] = useState(0);
  const [parentState, setParentState] = useState(0);
  const pid: number | null = selectPid(accountinfo?.accountinfo?.products);

  useEffect(() => {
    if (
      currentRoute?.page === 'Supplier Bids Details' &&
      agencyMemberId &&
      siblingProducts.length === 0
    ) {
      dispatch(getSiblingProducts({ memberId: agencyMemberId }));
    } else if (pid && memberTypeName === 'Free Agency' && siblingProducts.length === 0) {
      dispatch(getSiblingProducts({ productId: pid }));
    } else return;
  }, [pid, agencyMemberId, memberTypeName, siblingProducts, currentRoute, dispatch]);

  const upsellCountyProducts: Product[] = siblingProducts.filter(
    prod =>
      !existingProducts.map(ep => ep.productId).includes(prod.productId) &&
      prod.productType !== ProductType.State,
  );

  const upsellStateProducts: Product[] = siblingProducts.filter(
    prod =>
      !existingProducts.map(ep => ep.productId).includes(prod.productId) &&
      prod.productType === ProductType.State,
  );

  const identifier =
    relatedBids.length > 4
      ? 'upsell-bids-button'
      : upsellCountyProducts.length >= 2
      ? 'upsell-county-notifications-button'
      : 'upsell-state-notifications-button';

  const cardData: CardData[] = [
    {
      title: 'New Governments Past 12 Months',
      count: formatNumber(governments),
      classNames: 'default',
      count0Text: 'No New Governments Past 12 Months',
    },
    {
      title: 'New Governments Near You',
      count: formatNumber(nearByGovernments),
      classNames: 'default',
      count0Text: 'No New Governments Near You',
    },
    {
      title: 'Total Active Bids',
      count: formatNumber(totalBids),
      classNames: 'default',
      count0Text: '',
    },
    {
      title: 'New Suppliers Past 30 Days',
      count: formatNumber(suppliers),
      classNames: 'default',
      count0Text: 'No New Suppliers Past 30 Days',
    },
  ];

  const bidMessage = (
    <div>
      <h6>You missed out on</h6>
      <span className='upsell-big'>{relatedBids.length}</span>
      <h6>
        bid {pluralize(relatedBids.length, 'notification', 'notifications')} in the last 12 months
      </h6>
    </div>
  );

  const agencyCountyMessage = (
    <div>
      <h6>You could be getting notifications from</h6>
      <span className='upsell-big'>{upsellCountyProducts.length}</span>
      <h6>
        other {pluralize(upsellCountyProducts.length, 'agency', 'agencies')}{' '}
        {currentRoute?.page === 'Supplier Bids Details' ? '' : 'in your county'}
      </h6>
    </div>
  );

  const agencyStateMessage = (
    <div>
      <h6>You could be getting notifications from</h6>
      <span className='upsell-big'>{upsellStateProducts.length}</span>
      <h6>other {pluralize(upsellStateProducts.length, 'agency', 'agencies')} in your state</h6>
    </div>
  );

  const upsellCardData: {
    message: JSX.Element;
    linkMessage: string;
    isVisible: boolean;
    identifier: string;
    amplitudeMessage: string;
    productRecommended: string;
    productIds?: string;
    productLength: number;
  }[] = [
    {
      message: agencyCountyMessage,
      linkMessage: 'Get more notifications',
      isVisible: upsellCountyProducts.length >= 2,
      identifier: 'upsell-county-notifications-button',
      amplitudeMessage: 'Bid notifications in their county, Other agencies in their county',
      productRecommended: upsellCountyProducts
        .map((product: Product) => product.shortName)
        .join(', '),
      productIds: parentCounty.toString(),
      productLength: upsellCountyProducts.length,
    },
    {
      message: bidMessage,
      linkMessage: 'Get more bid opportunities',
      isVisible: relatedBids.length > 4,
      identifier: 'upsell-bids-button',
      amplitudeMessage: 'Bid notifications in their county, Other agencies in their county',
      productRecommended: relatedBids.map((item: any) => item.bidName).join(', '),
      productIds: upsellCountyProducts.map((product: Product) => product.productId).join(', '),
      productLength: relatedBids.length,
    },
    {
      message: agencyStateMessage,
      linkMessage: 'Get more notifications',
      isVisible: upsellStateProducts.length > 0 && upsellCountyProducts.length < 2,
      identifier: 'upsell-state-notifications-button',
      amplitudeMessage: 'Bid notifications in their state, Other agencies in their state',
      productRecommended: upsellStateProducts
        .map((product: Product) => product.shortName)
        .join(', '),
      productIds: parentState.toString(),
      productLength: upsellStateProducts.length,
    },
  ]
    .filter(c => c.isVisible)
    .filter(c => c.identifier === identifier);

  const card = !!upsellCardData.find(c => c.identifier === identifier);

  useEffect(() => {
    // don't send this message if there isn't a visible card
    if (!(auth?.memberId && card)) return;

    TrackGAEvents({
      category: 'Upsell',
      action: `Viewed Dashboard Message ${identifier}`,
      label: `${auth?.memberId}`,
    });
  }, [auth, identifier, card]);

  const cards = (
    <div className='row'>
      {cardData.map((data, idx) => {
        const { title, count, classNames, count0Text } = data;
        return (
          <div className={cardClass(auth?.memberType as any, idx)} key={idx}>
            <CardCount
              title={title}
              count={count}
              classNames={classNames}
              count0Text={count0Text}
            />
          </div>
        );
      })}
    </div>
  );

  const UpsellCards = () => {
    const handleClick = (amplitudeMessage: string, productLength: number, productIds?: string) => {
      TrackGAEvents({
        category: 'Upsell',
        action: `Clicked Dashboard Message ${identifier}`,
        label: `${auth?.memberId}`,
      });

      dispatch(
        trackAmplitudeUserActions({
          title: 'upgrade - click fomobox',
          desc: 'Click on FOMObox on the dashboard',
          data: {
            Message: amplitudeMessage,
            'Product Recommendation': sibilingParentCounty,
            'FOMObox Value': productLength,
          },

          isStore: true,
        }),
      );

      navHistory.push(`/subscription/renewal${productIds ? `?pid=${productIds}` : ''}`);
    };

    return (
      <div id='upsell-card'>
        {upsellCardData.map(
          ({ message, linkMessage, amplitudeMessage, productLength, productIds }, idx) => {
            return (
              <UpsellCard
                key={idx}
                message={message}
                linkMessage={linkMessage}
                handleClick={() => handleClick(amplitudeMessage, productLength, productIds)}
                isLast={idx === upsellCardData.length - 1}
              />
            );
          },
        )}
      </div>
    );
  };

  useEffect(() => {
    const setAgencyPrepopulateData = () => {
      if (allProducts.state === 'hasValue') {
        let agency: ProductApiResponse | null | undefined;
        if (currentRoute?.page === 'Supplier Bids Details') {
          agency = allProducts?.contents?.find(product => product.productName === agencyName);
        } else {
          agency =
            accountinfo?.accountinfo?.accountinfo?.products &&
            accountinfo?.accountinfo?.accountinfo?.products.length > 0
              ? allProducts?.contents?.find(
                  product =>
                    product.productId ===
                    accountinfo?.accountinfo?.accountinfo?.products[0].productId,
                )
              : null;
        }

        agency && agency.parentId && setParentCounty(agency?.parentId);
        const county = allProducts?.contents?.find(
          product => product.productId === agency?.parentId,
        );
        const stateId = county?.parentId || 0;
        stateId !== 0 && setParentState(stateId);
        if (county && (upsellCountyProducts.length >= 2 || relatedBids.length > 4)) {
          const countyGroup = `${county.productGroupId}_${county.parentId}_${county.productId}`;
          dispatch(
            setSubscriptionDetails({
              subscribedCounties: [county.productId],
              subscribedCountiesGroup: [countyGroup],
            }),
          );
        } else {
          dispatch(setSubscriptionDetails({ subscribedStates: [stateId] }));
        }
      }
    };
    (isFreeAgency || currentRoute?.page === 'Supplier Bids Details') && setAgencyPrepopulateData();
  }, [
    allProducts?.contents,
    allProducts.state,
    isFreeAgency,
    accountinfo?.accountinfo?.accountinfo?.products,
    relatedBids.length,
    upsellCountyProducts.length,
    agencyName,
    currentRoute,
    dispatch,
  ]);

  const shouldRender =
    memberTypeName &&
    auth?.memberId &&
    ((isFreeAgency && upsellCardData.length) || !isFreeAgency || isBasicSupplier);

  return shouldRender ? (
    <div
      className={`row ${
        currentRoute?.page === 'Supplier Bids Details' ? 'justify-content-center' : null
      }`}
    >
      <div className={currentRoute?.page === 'Supplier Bids Details' ? 'col-md-8' : 'col-md-12'}>
        {currentRoute?.page !== 'Supplier Bids Details' && (
          <h3 className='mt-5'>DemandStar Network</h3>
        )}
        {isBasicSupplier ? (
          <UpsellBasic accountInfo={accountinfo} currentRoute={currentRoute} />
        ) : isFreeAgency || currentRoute?.page === 'Supplier Bids Details' ? (
          <UpsellCards />
        ) : (
          cards
        )}
      </div>
    </div>
  ) : null;
}

export default RightsideBottom;
