import { useEffect, useState } from 'react';
import { DSCheckbox, DSTextInput } from '../../../../../library';
import { Buttons, Loader, NoResult } from '../../../../customcontrols';
import { useGetBidTagsCognitiveSearch } from '../../../../../query/queries/cognitiveSearch/getBidtagsCognitiveSearchData';
import { useGetCommodityCodesCognitiveSearch } from '../../../../../query/queries/cognitiveSearch/getcommoditiyCognitiveSearchData';
import { cleanInput, displayDate, getDisplayText, getStatusBarTheme, toastFn } from '../../../../../utils/helpers';
import { useGetAccountInfoCommodity } from '../../../../../query/queries/getAccountInfoCommodity';
import { postUpdateCommodityCodes } from '../../../../../store/actions';
import { useDispatch } from 'react-redux';
import { commonTags, findLegacyCommodities, mergeCommodityCodes } from '../../../../products/helpers';
import { CommodityState, useCommodities, useRefreshCommodities } from '../../../../../shared/hooks/useCommodity';
import { Commodity } from '../../../../products/commodity-code-AI/LegacyCommodityCodes';
import { DisplayTags } from './DispalyTags';
import { RelevantCognitiveBids } from './RelevantCognitiveBids';
import { CognitiveCommodityCodes } from './Suggested-SelectedCodes';

export const EditCommodityCodesAndTags = ({ setShowEditcommodityPopUp }) => {
    useRefreshCommodities();
    const { commodities } = useCommodities();
    const [codesFromLegacy, setCodesfromLegacy] = useState<Commodity[]>();
    const [searchQuery, setSearchQuery] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [initialRender, setInitialRender] = useState(true);
    const { data: existingCommodityAndTags, isFetching: existingdataFetching } = useGetAccountInfoCommodity();
    const existingTags = existingCommodityAndTags?.data?.result?.tags?.split(',')?.map(tag => tag.trim());
    const existingCodes = existingCommodityAndTags?.data?.result?.commodityCodes;
    const [formattedExistingCodes, setFormattedExistingCodes] = useState([]);
    const { data: bidTagsData, isFetching: isFetchingBidTags } = useGetBidTagsCognitiveSearch(searchQuery, { enabled: searchQuery?.length > 0 });
    const { data: commodityCodesData, isFetching: isFetchingCommodityCodes } = useGetCommodityCodesCognitiveSearch(searchQuery, { enabled: searchQuery?.length > 0 });
    const mergedCodes = mergeCommodityCodes(commodityCodesData, codesFromLegacy) as any;
    const [checkedCommodities, setCheckedCommodities] = useState([]);

    let highlightedTagsArr = [];
    const suggestedTagsopt = commodityCodesData?.value?.flatMap(item => {
        const hightlights = item['@search.captions'] ? item['@search.captions'][0]?.highlights : '';
        if (hightlights) {
            const result = hightlights?.match(/<em>(.*?)<\/em>/g)?.map(val => {
                return val.replace(/<\/?em>/g, '');
            }) || [];
            highlightedTagsArr = [...highlightedTagsArr, ...result];
        }
    });
    const suggestedTags = [... new Set(
        highlightedTagsArr
            .join(',')
            .replace(/-/g, '')
            .replace('.', ',')
            .replace('(', '')
            .replace(')', '')
            .replace(':', ',')
            .split(',')
            .map(Item => Item.trim().toLowerCase())
            .filter(Item => Item)),
    ];


    const sortedUniqueSuggestedTags = suggestedTags?.length > 0 ? suggestedTags
        ?.filter(word => word !== '' && word.length >= 3)
        ?.filter(word => {
            const lowerCaseSelectedTags = selectedTags.map(selectedTag => selectedTag.toLowerCase().trim());
            const lowerCaseCommonTags = commonTags.map(commonTag => commonTag.toLowerCase().trim());
            return (
                !lowerCaseSelectedTags.includes(word) &&
                !lowerCaseCommonTags.includes(word)
            );
        }) : [];


    const dispatch = useDispatch();

    const handleAddtagsCommoditiesClick = async () => {
        const commodityIds = checkedCommodities?.map((comm: any) => comm.commodityid);
        const combinedTags = [...(selectedTags || [])];

        if (commodityIds?.length || selectedTags?.length) {
            dispatch(postUpdateCommodityCodes({ data: { commodityIds, combinedTags } }));
            setShowEditcommodityPopUp();
        }
    };

    const handleSearch = () => {
        setIsSearchClicked(true);
        setSearchQuery(cleanInput(inputValue));
    };


    const handleTagSelect = (tag: string) => {
        const cleanedTag = tag.replace(/\s*\(\d+\/10\)$/, '');

        setSelectedTags(prevTags => {
            const updatedTags = [...prevTags, cleanedTag];

            const suggestedCodes = commodityCodesData?.value.filter(commodity =>
                commodity.trimmedtags?.split(',').map(tag => tag.trim().toLowerCase()).includes(cleanedTag.toLowerCase()),
            ) || [];

            setCheckedCommodities(prevCodes => {
                const newCodes = [...prevCodes, ...suggestedCodes.map(code => {
                    const isExistingCode = formattedExistingCodes.some(existingCode => existingCode.commodityid === code.commodityid);
                        return isExistingCode
                        ? { ...code, manuallyAdded: true, previouslyExistingCode: true }
                        : code;
                })];
                const parentCodes = new Set(
                    newCodes
                        .filter(code => code.fullcode?.endsWith('00'))
                        .map(code => code.fullcode),
                );
                  const filteredCodes = newCodes?.filter((code, index, self) => {
                    const isChildOfExistingParent =
                        !code.fullcode?.endsWith('00') &&
                        parentCodes?.has(code.fullcode?.slice(0, -2) + '00');
                    return (
                        !isChildOfExistingParent &&
                        index === self.findIndex(c => c.commodityid === code.commodityid)
                    );
                });
                return filteredCodes;
            });

            return updatedTags;
        });
    };


    const handleTagRemove = (tag: string) => {
        const updatedTags = selectedTags.filter(t => t !== tag);
        setSelectedTags(updatedTags);


        const remainingSuggestedCodes = updatedTags.flatMap((remainingTag) => {
            return checkedCommodities.filter(commodity =>
                commodity.trimmedtags?.split(',').map(t => t.trim().toLowerCase()).includes(remainingTag.toLowerCase()),
            ) || [];
        });

        const uniqueSuggestedCodes = remainingSuggestedCodes.filter((code, index, self) =>
            index === self.findIndex(c => c.commodityid === code.commodityid),
        );
        setCheckedCommodities(prevCodes => {
            return prevCodes.filter(code =>
                uniqueSuggestedCodes.some(suggestedCode => suggestedCode.commodityid === code.commodityid) ||
                code.manuallyAdded,
            );
        });
    };

    const handleCheckboxChange = (commodity) => {
        setCheckedCommodities((prevCheckedCommodities) => {
            const isCommodityChecked = prevCheckedCommodities.some(
                (item) => item.commodityid === commodity.commodityid,
            );
            const isCommodityExisting = formattedExistingCodes?.some(
                (item) => item.commodityid === commodity.commodityid,
            );
            const isParentCommodity = commodity.fullcode.endsWith('00');
            if (isCommodityChecked) {
                return prevCheckedCommodities.filter(
                    (item) => item.commodityid !== commodity.commodityid,
                );
            } else {
                if (isParentCommodity) {
                    const parentPrefix = commodity.fullcode.slice(0, -2);
                    const updatedCommodities = prevCheckedCommodities.filter(
                        (item) => !item.fullcode.startsWith(parentPrefix) || item.fullcode.endsWith('00'),
                    );
                    toastFn('success', `Codes under ${commodity.commodity} are selected automatically `);
                    return [...updatedCommodities,{...commodity,manuallyAdded: true,previouslyExistingCode: isCommodityExisting}];
                } else {
                    const parentPrefix = commodity.fullcode.slice(0, -2) + '00';
                    const updatedCommodities = prevCheckedCommodities.filter(
                        (item) => item.fullcode !== parentPrefix,
                    );
                    return [...updatedCommodities,{...commodity,manuallyAdded: true,previouslyExistingCode: isCommodityExisting}];
                }
            }
        });

    };

    useEffect(() => {
        if (commodities && isSearchClicked) {
            const commoMap: Map<string, Commodity> = new Map();

            commodities.forEach((comm: CommodityState) => {
                const formattedCode = `${comm.commodityGroup}-${comm.commodityCategory}-${comm.commodityCode}`;

                commoMap.set(formattedCode, {
                    commodityCategory: comm.commodityCategory,
                    commodityCode: comm.commodityCode,
                    commodityDescription: comm.commodityDescription?.toUpperCase(),
                    commodityGroup: comm.commodityGroup,
                    commodityId: comm.commodityId,
                    formattedCode: formattedCode,
                    fullCode: formattedCode,
                    isSelected: false,
                });
            });

            const foundCommodities = findLegacyCommodities(commoMap, inputValue);
            setCodesfromLegacy(foundCommodities);
        }
    }, [inputValue, commodities, isSearchClicked]);

    useEffect(() => {
        if (initialRender && checkedCommodities.length === 0) {
            setSelectedTags(existingTags);
            const transformCommodity = (commodities) => {
                return commodities.map(commodity => ({
                    commodity: commodity.commodityDescription,
                    commodityid: commodity.commodityId,
                    fullcode: commodity.fullCode,
                    manuallyAdded: true,
                    trimmedtags: commodity.trimmedTags,
                    previouslyExistingCode: true,
                }));
            };
            const commodityCodes = existingCodes || [];
            const transformedCommodities = transformCommodity(commodityCodes);
            setCheckedCommodities(transformedCommodities);
            setFormattedExistingCodes(transformedCommodities);
            setSearchQuery(existingCommodityAndTags?.data?.result?.tags || '');
            setInitialRender(false);
        }
    }, [existingCodes, existingTags, existingCommodityAndTags?.data?.result?.tags, checkedCommodities.length, initialRender]);

    return (
        <><Loader loading={isFetchingBidTags || isFetchingCommodityCodes || existingdataFetching} />
            <div>
                <p>
                    To get notifications about bids that may be a good fit for your business, you must choose
                    commodity codes. Adding codes is free!
                </p>
                <p className='reg-intro no-top-padding '>
                    Take control of your bid notification experience and maximize your chances of finding
                    opportunities that are tailored to your needs. Add your key tags here and let us assist
                    you in uncovering the most relevant and valuable bid opportunities in your industry.
                </p>
                <p>For example if you need notifications of bids related to Automobiles. search with keywords 'Automobiles' / 'vehicles' we will show related tags and commodity codes that may suit your need</p>
            </div>
            <div className='row'>
                <div className='col col-7 mt-2'>
                    <DSTextInput
                        name={'Cognitive search bar'}
                        value={inputValue}
                        onChange={(value: string) => setInputValue(value)}
                        placeholder={'Example : Infrastructure , Automobiles , Dairy , Agriculture '}
                    />
                </div>
                <div className='col col-5'>
                    <Buttons
                        classNames='bttn-primary mt-1'
                        text='Search'
                        title='Search'
                        type='submit'
                        onClick={handleSearch}
                        disable={inputValue.length < 3}
                    />
                </div>
            </div>
            <DisplayTags
            suggestedTags={suggestedTags}
            selectedTags={selectedTags}
            sortedUniqueSuggestedTags={sortedUniqueSuggestedTags}
            handleTagSelect={handleTagSelect}
            handleTagRemove={handleTagRemove}
            />

            <div className="row">
                <div className='col col-7 mt-2'>
                    <RelevantCognitiveBids
                    bidTagsData={bidTagsData}
                    isSearchClicked={isSearchClicked}
                    pageFor={'editCommodity'}
                    />

                </div>
                <div className='col col-5 '>
                    <CognitiveCommodityCodes
                    mergedCodes={mergedCodes}
                    checkedCommodities={checkedCommodities}
                    handleCheckboxChange={handleCheckboxChange}
                    formattedExistingCodes={formattedExistingCodes}
                    />

                </div>
            </div>
            <div className="d-flex justify-content-between">
                <div className="d-flex ">
                    <Buttons
                        classNames='bttn-secondary mt-3 ml-5'
                        text='Not Now'
                        title='Not now '
                        type='button'
                        onClick={() => setShowEditcommodityPopUp(false)}
                    />
                </div>
                <div className="d-flex ">
                    <Buttons
                        classNames='bttn-primary mt-3 mr-5 '
                        text='Add Tags and Commodity Codes'
                        title={(checkedCommodities?.length === 0) ? 'Select Atleast one Commodity code to Enable' : 'Add Tags and Commodity Codes '}
                        type='submit'
                        onClick={handleAddtagsCommoditiesClick}
                        disable={checkedCommodities?.length === 0}
                    />
                </div>
            </div>
        </>
    );
};
