export const alertPercentage = 40;

export const registrationComponent = {
  AccountConfirmation: 'AccountConfirmation',
  ChooseAgency: 'ChooseAgency',
  ChooseSubscription: 'ChooseSubscription',
  CommodityCode: 'CommodityCode',
  CompleteProfile: 'CompleteProfile',
  CreateAccount: 'CreateAccount',
  EmailAccount: 'EmailAccount',
  ReviewOrder: 'ReviewOrder',
} as const;

export const promptSkipPages = [
  registrationComponent.AccountConfirmation,
  registrationComponent.EmailAccount,
];
export const legalAdDefault = {
  legalAdIntro:
    'BLANK Board of BLANK Commissioners requests interested parties to submit formal sealed bids/proposals for the above referenced.',
  legalAdCostInfo: 'plus shipping and handling.',
  legalAdDueDateTime:
    'must receive bids no later than said date and time. Bids received after such time will be returned unopened.',
  legalAdAdditional:
    'NOTE: ALL PROSPECTIVE BIDDERS/RESPONDENTS ARE HEREBY CAUTIONED NOT TO CONTACT ANY MEMBER OF THE blank STAFF OR OFFICIALS OTHER THAN THE SPECIFIED CONTACT PERSON.',
  legalAdClosing: "M/WBE's are encouraged to participate in the bid process.",
  planholderDisplayType: 'AG',
  eQuoteIntro: 'Q123',
  eQuoteInsurance: '',
};

export const taxPercent = 6;
