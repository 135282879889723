import { memo, useEffect, useState } from 'react';
import moment from 'moment-timezone';

// import BidActivityReport from './bidactivityreport';
// import QuoteActivityReport from './quoteactivityreport';
import BidQuoteActivityReports from './bidquoteactvityreports';

import { activitiesTypes, memberinfoTypes } from '../../../../types';
import { Buttons, SelectBox } from '../../../customcontrols';
import {
  reportbidStatus,
  reportOptions,
  reportotherbidStatus,
  reportotherquoteStatus,
  reportquoteStatus,
  usaDateFormat,
} from '../../../../utils/constants';

import { deprecatedGetTimeZoneDateTime } from '../../../../utils/helpers';
import { reactselectTypes } from '../../../../types/shared';
import { setActivitiesDetails } from 'src/store/actions';
import {
  bidreports_quotesInitialValue,
  quotereports_quotesInitialValue,
} from 'src/store/reducers/activities';

interface PropTypes {
  activities: activitiesTypes;
  memberinfo: memberinfoTypes;
  getBidActivityReport: any;
  exportBidActivityReport: any;
  getQuoteActivityReport: any;
  exportQuoteActivityReport: any;
  setActivitiesDetails: any;
  memberInfo: any;
}

const ActivityReport = (props: PropTypes) => {
  const { activities, exportBidActivityReport, exportQuoteActivityReport, memberinfo } = props;

  const {
    bidreports_status,
    bidreports_startDate,
    bidreports_endDate,
    quotereports_status,
    quotereports_startDate,
    quotereports_endDate,
  } = activities || {};
  const { mn = '', tzfn } = memberinfo || {};

  // Report Selection
  const [selectedReport, setSelectedReport] = useState(reportOptions[0]);

  const onChangeSelectReport = (name: string, value: reactselectTypes) => {
    setSelectedReport(value);
  };
  useEffect(() => {
    return () => {
      setActivitiesDetails({
        activities_quotereports_search: false,
        quotereports_startDate: moment().subtract(1, 'months').format(usaDateFormat),
        quotereports_endDate: moment().format(usaDateFormat),
        quotereports_status: reportquoteStatus[0],
        activities_quotereports_search_result: quotereports_quotesInitialValue,
        activities_bidreports_search: false,
        bidreports_startDate: moment().subtract(1, 'months'),
        bidreports_endDate: moment(),
        bidreports_status: reportbidStatus[0],
        activities_bidreports_search_result: bidreports_quotesInitialValue,
      });
    };
  });

  const handleExportClick = () => {
    if (selectedReport.value === 'bids') {
      exportBidActivityReport({
        startDate: bidreports_startDate ? moment(bidreports_startDate).format(usaDateFormat) : null,
        endDate: bidreports_endDate ? moment(bidreports_endDate).format(usaDateFormat) : null,
        statusList:
          bidreports_status?.value === 'all' ? reportotherbidStatus : bidreports_status?.value,
        agencyName: mn,
        reportRunTime: deprecatedGetTimeZoneDateTime(tzfn),
      });
    } else {
      exportQuoteActivityReport({
        startDate: quotereports_startDate
          ? moment(quotereports_startDate).format(usaDateFormat)
          : null,
        endDate: quotereports_endDate ? moment(quotereports_endDate).format(usaDateFormat) : null,
        statusList:
          quotereports_status?.value === 'all'
            ? reportotherquoteStatus
            : quotereports_status?.value,
        agencyName: mn,
        reportRunTime: deprecatedGetTimeZoneDateTime(tzfn),
      });
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-lg-12' data-testid='activities.bids.reports.head'>
          <h2 className='arrowWrapper'>Solicitation Reports</h2>
          <Buttons
            title='Export to Excel'
            text='Export to Excel'
            classNames='bttn-primary float-right btnPTitle'
            onClick={handleExportClick}
            //disable={reports_list.length === 0}
          />
        </div>
        <div className='row ml-1 mb-3'>
          <div className='col-lg-12'>
            <div className='clearfix d-flex align-items-center py-3 activitiesFilter'>
              <SelectBox
                reactselect={true}
                label='Reports For'
                name='selectedReport'
                options={reportOptions}
                handleSelect={onChangeSelectReport}
                value={selectedReport}
              />
            </div>
          </div>
        </div>
      </div>
      <BidQuoteActivityReports report={selectedReport.value} {...props} />
    </>
  );
};

export default memo(ActivityReport);
