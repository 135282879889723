import { FlexContainerColumn, H5, H6, Large } from '@demandstar/components/styles';
import { DSEmail } from '@demandstar/components/email';

import { CartItemList, CartWrapper } from './styles';
import { commonEmail, TollFreeNumber } from 'src/utils/texts/common';
import { useCart, useRefreshCartTotal } from '.';

import { CartAlert } from './CartAlert';
import { CartItem } from './CartItem';
import { displayDate } from 'src/utils/helpers';
import { ProductsCopy } from 'src/utils/texts/supplier/subscription';
import { taxPercent } from '../../../utils/constants';
import { calculateTax } from '../helpers';

interface CartProps {
  /** indicates if this page is used for review */
  isReview?: boolean;
  /** indicates whether the delete icon should be displayed for cart line items */
  showDelIcon?: boolean;
}

/**
 * @description A cart used for selecting products & checking out
 * @default { isReview = false, showDelIcon = false }
 * @returns JSX.Element
 *
 * @example <Cart isReview={false} />
 */
export const Cart = ({ isReview = false, showDelIcon = true }: CartProps) => {
  useRefreshCartTotal();
  const {
    cartItems,
    cartRenewal,
    cartTotal,
    discountedCartTotal,
    expiryDate,
    maximumCartDiscount,
    recurringTotal,
    showCart,
  } = useCart();

  return (
    <CartWrapper isProrated={recurringTotal !== cartTotal}>
      {showCart && (
        <H5>{cartRenewal ? ProductsCopy.CartRenewalHeader : ProductsCopy.CartHeader}</H5>
      )}
      <CartItemList>
        {cartItems.map(ci => (
          <CartItem
            key={ci.productId}
            product={ci}
            isRemovable={showDelIcon && !isReview && !cartRenewal}
          />
        ))}
      </CartItemList>

      {window.location.pathname === '/app/registration' && (
        <>
          <H6 lineheight="1rem" marginTop="1rem">
            {ProductsCopy.CartTotalcharge}
            <span data-testid="product-total">
              ${discountedCartTotal.toFixed(2)}
            </span>
          </H6>

          <H6 lineheight="1rem">
            {ProductsCopy.ServiceCharge}{`( ${taxPercent}% ) :`}
            <span data-testid="service-charge">
              ${calculateTax(taxPercent, discountedCartTotal).toFixed(2)}
            </span>
          </H6>
        </>
      )}
      <H6>
        {ProductsCopy.CartTotal}
        <span data-testid="cart-total">
          $
          {(window.location.pathname === '/app/registration') ?
            (discountedCartTotal + calculateTax(taxPercent, discountedCartTotal)
            ).toFixed(2) :
            discountedCartTotal === Math.round(discountedCartTotal)
              ? discountedCartTotal
              : discountedCartTotal.toFixed(2)}

          {maximumCartDiscount > 0 ? ' *' : ''}
        </span>
      </H6>

      <FlexContainerColumn>
        {recurringTotal > 0 && recurringTotal !== cartTotal && (
          <>
            <Large>
              {`${
                discountedCartTotal <= 0
                  ? 'If you complete this change, your account will renew on'
                  : ProductsCopy.RenewsOn
              } ${displayDate(expiryDate)} for $${recurringTotal}.`}
            </Large>

            {!cartRenewal && <Large>{ProductsCopy.IncludesCurrentAndAdded}</Large>}

            {discountedCartTotal <= 0 && (
              <Large>
                If you would like to proceed with this change, please{' '}
                <DSEmail address={commonEmail.support}>email us</DSEmail> or call us at{' '}
                {TollFreeNumber}
              </Large>
            )}
          </>
        )}
        {!isReview && <CartAlert />}

        {maximumCartDiscount > 0 && (
          <Large>
            {`* Your total today includes a $${(discountedCartTotal === 0
              ? cartTotal
              : maximumCartDiscount
            ).toFixed(2)}
              discount for previously subscribed products.`}
          </Large>
        )}
      </FlexContainerColumn>
    </CartWrapper>
  );
};
