import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { Assert } from '@demandstar/components/utils';
import { Status } from '@demandstar/components/constants';

import * as services from './reminders.services';
import { ContractReminder, ContractReminderAPI } from './reminders';
import { contractRemindersState, remindersPreviewState } from './reminders.state';
import { tryCatchLog } from 'src/utils/errors';
import { useAlert } from 'src/components/common/alert';
import { useIdFromRoute } from 'src/shared/hooks/useIdFromRoute';
import { formatPayloadDates, getEmailArray } from '../AddReminder.utils';

export function toReminder(apiResponse: ContractReminderAPI): ContractReminder {
  return {
    ...apiResponse,
    remindDate: dayjs(apiResponse.remindDate),
    lastSent: apiResponse.lastSent ? dayjs(apiResponse.lastSent) : null,
    dueDate: dayjs(apiResponse.dueDate),
    emails: apiResponse.emails ?? [],
  };
}

/**
 * @description hook for accessing reminders
 * @param {string} contractIdProp - optional contractId
 * - will filter to one contract if passed (or if there is a contractId in the route path)
 * - otherwise, will return all reminders for the user
 */
export function useReminders(contractIdProp?: string) {
  const reminderAlertId = 'reminder-alert';
  const alert = useAlert(reminderAlertId);
  const { contractId: idFromRoute } = useIdFromRoute();
  const contractId = contractIdProp || idFromRoute;
  const previewVisibleLoadable = useRecoilValueLoadable(remindersPreviewState);
  const previewVisible = useMemo(
    () => !!previewVisibleLoadable.valueMaybe(),
    [previewVisibleLoadable],
  );

  const remindersLoadable = useRecoilValueLoadable(contractRemindersState(contractId));

  const reminders = useMemo(() => {
    return remindersLoadable.valueMaybe() || [];
  }, [remindersLoadable]);

  const createReminder = useRecoilCallback(
    ({ set, snapshot }) =>
      async (reminder: ContractReminder) => {
        alert.hide();

        /* Get reminders states as promises so these can load while we create the reminder */
        const remindersPromise = snapshot.getPromise(contractRemindersState(reminder.contractId));
        const allRemindersPromise = snapshot.getPromise(contractRemindersState(undefined));

        /* Create the reminder */
        const apiResponse = await tryCatchLog(
          () => services.createReminder(reminder),
          'useReminders => createReminder',
          () =>
            alert.show({
              dataTestId: 'create-reminder-alert-error',
              message: 'An error occurred while creating the reminder.',
              type: Status.Error,
            }),
        );
        const response = toReminder(apiResponse);

        alert.show({
          dataTestId: 'create-reminder-alert-success',
          message: 'The reminder has been created.',
          type: Status.Success,
        });

        /* Update the states */
        set(contractRemindersState(reminder.contractId), [...(await remindersPromise), response]);
        set(contractRemindersState(undefined), [...(await allRemindersPromise), response]);

        return response;
      },
    [alert],
  );

  const dismissReminder = useRecoilCallback(
    ({ set, snapshot }) =>
      async (reminder: ContractReminder) => {
        alert.hide();
        const { id } = reminder;

        /* Get reminders state as promises so this can load while we create the reminder */
        const allRemindersPromise = snapshot.getPromise(contractRemindersState(undefined));
        const reminderToRemovePromise = allRemindersPromise.then(allReminders =>
          allReminders.find(r => r.id === id),
        );
        const remindersPromise = reminderToRemovePromise.then(r =>
          r?.contractId ? snapshot.getPromise(contractRemindersState(r.contractId)) : undefined,
        );

        const apiResponse = await tryCatchLog(
          () =>
            services.updateReminder({
              ...reminder,
              dismissed: true,
            }),
          'useReminders => dismissReminder',
          () =>
            alert.show({
              dataTestId: 'dismiss-reminder-alert-error',
              message: 'An error occurred while dismissing the reminder.',
              type: Status.Error,
            }),
        );
        const response = toReminder(apiResponse);

        alert.show({
          dataTestId: 'dismiss-reminder-alert-success',
          message: 'The reminder has been dismissed.',
          type: Status.Success,
        });

        /* Update the states */
        set(
          contractRemindersState(undefined),
          (await allRemindersPromise).filter(r => r.id !== id),
        );

        const reminderToRemove = await reminderToRemovePromise;
        const reminders = await remindersPromise;
        if (reminderToRemove?.contractId && reminders) {
          set(
            contractRemindersState(reminderToRemove.contractId),
            reminders.filter(r => r.id !== id),
          );
        }

        return response;
      },
    [alert],
  );

  const sendReminder = useRecoilCallback(
    ({ set, snapshot }) =>
      async (reminderId: string) => {
        alert.hide();

        /* Get reminders state as promises so this can load while we create the reminder */
        const allRemindersPromise = snapshot.getPromise(contractRemindersState(undefined));

        const reminderToSend = await allRemindersPromise.then(allReminders =>
          allReminders.find(r => r.id === reminderId),
        );

        Assert(reminderToSend, 'We must have found a reminder');
        const reminderContractId = reminderToSend.contractId;

        const contractRemindersPromise = snapshot.getPromise(
          contractRemindersState(reminderContractId),
        );

        const apiResponse = await tryCatchLog(
          () =>
            services.createReminder({
              ...reminderToSend,
              remindDate: formatPayloadDates(dayjs()),
              lastSent: null,
              dueDate:formatPayloadDates(reminderToSend.dueDate),
              emails:reminderToSend.emails ? getEmailArray(reminderToSend.emails as any) : [],
            }),
          'useReminders => sendReminder',
          () =>
            alert.show({
              dataTestId: 'send-reminder-alert-error',
              message: 'An error occurred while resending the reminder.',
              type: Status.Error,
            }),
        );
        const response = toReminder(apiResponse);

        alert.show({
          dataTestId: 'send-reminder-alert-success',
          message: 'The reminder will be sent again.',
          type: Status.Success,
        });

        /* Update the states */
        set(contractRemindersState(undefined), [
          ...(await allRemindersPromise).map(reminder =>
            reminder.id === reminderId ? response : reminder,
          ),
        ]);

        set(contractRemindersState(reminderToSend.contractId), [
          ...(await contractRemindersPromise).map(reminder =>
            reminder.id === reminderId ? response : reminder,
          ),
        ]);

        return response;
      },
    [alert],
  );

  const toggleReminderPreview = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const currentState = await snapshot.getPromise(remindersPreviewState);
        services.setPreviewPreferences(currentState);
        set(remindersPreviewState, !currentState);
      },
    [],
  );

  const updateReminder = useRecoilCallback(
    ({ set, snapshot }) =>
      async (reminder: ContractReminder) => {
        alert.hide();

        /* Get reminders states as promises so these can load while we create the reminder */
        const remindersPromise = snapshot.getPromise(contractRemindersState(reminder.contractId));
        const allRemindersPromise = snapshot.getPromise(contractRemindersState(undefined));

        /* Create the reminder */
        const apiResponse = await tryCatchLog(
          () => services.createReminder({...reminder,
            remindDate: formatPayloadDates(reminder.remindDate),
            dueDate: formatPayloadDates(reminder.dueDate),
            emails:reminder.emails ? getEmailArray(reminder.emails as any) : [],
          }),
          `useReminders => updateReminder(${reminder.id})`,
          () =>
            alert.show({
              dataTestId: 'update-reminder-alert-error',
              message: 'An error occurred while updating the reminder.',
              type: Status.Error,
            }),
        );
        const response = toReminder(apiResponse);

        alert.show({
          dataTestId: 'update-reminder-alert-success',
          message: 'The reminder has been updated.',
          type: Status.Success,
        });

        /* Update the states */
        set(contractRemindersState(reminder.contractId), [
          ...(await remindersPromise).filter(r => r.id !== response.id),
          response,
        ]);
        set(contractRemindersState(undefined), [
          ...(await allRemindersPromise).filter(r => r.id !== response.id),
          response,
        ]);

        return response;
      },
    [alert],
  );

  return {
    createReminder,
    dismissReminder,
    previewVisible,
    reminderAlertId,
    reminders,
    remindersLoadable,
    sendReminder,
    toggleReminderPreview,
    updateReminder,
  };
}
