import styled from 'styled-components';

import { FlexContainer, H2, Large, Margin } from '@demandstar/components/styles';

import { DSSearch } from '@demandstar/components/search';

import * as texts from '../../search/contracts/ContractSearch.texts';
import { ContractResult, useContractSearch } from '../../search/contracts';
import { Feature, FeatureFlagKey } from 'src/components/common/feature-flag';

import { AddContracts } from './AddContracts';
import { CMTabNavigation } from '../../navigation/CMTabNavigation';
import { ContractLimitAlert } from '../components/ContractLimitAlert';
import { LoadableWrapper } from 'src/components/common/loading/LoadableWrapper';
import { Reminders } from '../reminders/Reminders';
import { useDSSelector } from 'src/store/reducers';
import { hasPermission } from 'src/utils/helpers';
import { userPermissionKeys } from 'src/utils/constants';
import history from 'src/utils/history';
import { useEffect } from 'react';
import { useAuthContext } from 'src/auth/AuthProvider';
import { Authorization } from 'src/types';

const MarginedLarge = styled(Large)`
  margin-bottom: ${Margin.Extra};
  display: inline-block;
`;

export const ContractSearch = () => {
  const { allContractsCount, allContractsLoadable, contractLimitOK, filters, search, sort } =
    useContractSearch();

  const { auth } = useAuthContext();
  const memberinfo = useDSSelector(state => state.memberinfo);

  const { prms } = memberinfo;
  // TODO: This logic is repeated in several components, should be abstracted into a utility or hook
  const { accountPermissions: authPerms, mainContact: isPrimaryContact = false } =
    auth || ({} as Authorization);
  const accountPermissions = prms ? prms : authPerms;

  const hasManageBidsPermission =
    hasPermission(accountPermissions, userPermissionKeys.manageBids) || isPrimaryContact;
  useEffect(() => {
    if (hasManageBidsPermission === false) {
      history.push('/buyers/dashboard');
    }
  }, [hasManageBidsPermission]);

  return (
    <Feature flag={FeatureFlagKey.ContractManagement} redirect={'/'}>
      <CMTabNavigation />
      <Reminders preview />
      <H2 data-testid={'contracts.search.header'}>{texts.contracts}</H2>
      {contractLimitOK ? (
        <LoadableWrapper loadable={allContractsLoadable}>
          {<MarginedLarge>{texts.youAreManaging(allContractsCount)}</MarginedLarge>}
        </LoadableWrapper>
      ) : null}
      {allContractsCount > 0 ? (
        <DSSearch
          emptyMessage={texts.noResults}
          filters={filters}
          recordKey='id'
          renderResult={ContractResult}
          search={search}
          sort={sort}
        />
      ) : null}
      {!contractLimitOK ? (
        <FlexContainer>
          <ContractLimitAlert />
        </FlexContainer>
      ) : (
        <AddContracts />
      )}
    </Feature>
  );
};
