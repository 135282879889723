export const DisplayTags = ({ suggestedTags, selectedTags, sortedUniqueSuggestedTags, handleTagSelect, handleTagRemove }) => {
    return (
        <>
            <div className="col-12">
                {suggestedTags?.filter(tag => !selectedTags.includes(tag))?.length > 0 && (
                    <div className='row mt-2  '>
                        <div className='col'>
                            <h4> Suggested Tags :</h4>
                            <div className='row tagsBlock' >
                                {sortedUniqueSuggestedTags?.filter(tag => !selectedTags.includes(tag)).map(tag => (
                                    <div key={tag} className="search-tag mr-1 ml-1 cursorPointer" onClick={() => handleTagSelect(tag)}>
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {(selectedTags?.filter(tag => tag)?.length > 0) && (
                    <div className='row mt-2'>
                        <div className='col'>
                            <h4>Selected Tags: </h4>
                            <div className='row'>
                                {selectedTags
                                    .filter(tag => tag)
                                    .map(tag => (
                                        <div key={tag} className="selected-tag mr-1 ml-1 bold">
                                            {tag}
                                            <span
                                                className="ml-1 remove-tag cursorPointer"
                                                onClick={() => handleTagRemove(tag)}
                                            >
                                                &#10005;
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div></>
    );
};
