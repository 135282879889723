import { BidCategory, quoteExternalStatus } from 'src/utils/constants';

export const setDashboardStaticticsData = (response: any, responseTrackingData: any) => {
  const responsetracking = responseTrackingData;

  let opensCount = 0,
    Awarded = 0,
    Cancelled = 0,
    MyBids = 0,
    UnderEvaluation = 0,
    incompletecount = 0,
    Completed = 0,
    RecommendationofAward = 0;

  response?.data?.result?.bids.map((item: { categoryType: BidCategory; count: number }) => {
    if (item.categoryType === BidCategory.Active) {
      opensCount = opensCount + item.count;
    } else if (item.categoryType === BidCategory.Awarded) {
      Awarded = Awarded + item.count;
    } else if (item.categoryType === BidCategory.Upcoming) {
      incompletecount = incompletecount + item.count;
    } else if (item.categoryType === BidCategory.Cancelled) {
      Cancelled = Cancelled + item.count;
    } else if (item.categoryType === BidCategory.Completed) {
      Completed = Completed + item.count;
    } else if (item.categoryType === BidCategory.MyBids) {
      MyBids = MyBids + item.count;
    } else if (item.categoryType === BidCategory.UnderEvaluation) {
      UnderEvaluation = UnderEvaluation + item.count;
    } else if (item.categoryType === BidCategory.RecommendationOfAward) {
      RecommendationofAward = RecommendationofAward + item.count;
    }
    return item;
  });

  let qopensCount = 0,
    qAwarded = 0,
    qCancelled = 0,
    MyQuotes = 0,
    qclosed = 0,
    incompleteCount = 0;

  response.data.result.quotes.map((item: { categoryType: string; count: number }) => {
    if (item.categoryType === quoteExternalStatus.open) {
      qopensCount = qopensCount + item.count;
    } else if (item.categoryType === quoteExternalStatus.awarded) {
      qAwarded = qAwarded + item.count;
    } else if (item.categoryType === quoteExternalStatus.incomplete) {
      incompleteCount = incompleteCount + item.count;
    } else if (item.categoryType === quoteExternalStatus.cancelled) {
      qCancelled = qCancelled + item.count;
    } else if (item.categoryType === quoteExternalStatus.closed) {
      qclosed = qclosed + item.count;
    } else if (item.categoryType === 'MQ') {
      MyQuotes = MyQuotes + item.count;
    }
    return item;
  });

  const statistics = {
    bids: [
      { title: 'Active', categoryType: BidCategory.Active, count: opensCount },
      { title: 'Upcoming', categoryType: BidCategory.Upcoming, count: incompletecount },
      {
        title: 'Under Evaluation',
        categoryType: BidCategory.UnderEvaluation,
        count: UnderEvaluation,
      },
      {
        title: 'Recommendation Of Award',
        categoryType: BidCategory.RecommendationOfAward,
        count: RecommendationofAward,
      },
      { title: 'Awarded', categoryType: BidCategory.Awarded, count: Awarded },
      { title: 'Completed', categoryType: BidCategory.Completed, count: Completed },
      { title: 'Cancelled', categoryType: BidCategory.Cancelled, count: Cancelled },
      { title: 'My Bids', categoryType: BidCategory.MyBids, count: MyBids },
    ],
    quotes: [
      { title: 'Open', categoryType: quoteExternalStatus.open, count: qopensCount },
      {
        title: 'Incomplete',
        categoryType: quoteExternalStatus.incomplete,
        count: incompleteCount,
      },
      { title: 'Awarded', categoryType: quoteExternalStatus.awarded, count: qAwarded },
      { title: 'Closed', categoryType: quoteExternalStatus.closed, count: qclosed },
      { title: 'Cancelled', categoryType: quoteExternalStatus.cancelled, count: qCancelled },
      { title: 'My Quotes', categoryType: 'MQ', count: MyQuotes },
    ],
  };

  if (responsetracking?.data?.result?.bids.length > 0) {
    response.data.result.bids.push(responsetracking?.data?.result?.bids[0]);
  }

  const checkWatching = response.data.result.bids.some((i: any) => i.category === 'Watching')?true:false;
  if(checkWatching){
    response.data.result.bids = uniqByKeepLast(
    response.data.result.bids,
    (it: { category: any }) => it.category);
  }
  return { dashboardData: response.data.result, statistics: statistics };
};

const uniqByKeepLast = (data: any, key: any) => {
  return [...new Map(data.map((x: any) => [key(x), x])).values()];
};
