import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getEunaLinks } from '../../store/services/shared';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';
import { track } from '../../utils/telemetry';

const GetEunaLinks = (data: any) => {
  const response = getEunaLinks(data);
  return response;
};

export const useGetEunaLinks = (data: any, options = {}) => {
  return useQuery({
    queryKey: ['EunaLinks'],
    queryFn: async () => {
      try {
        return await GetEunaLinks(data);
      } catch (error) {
        track('GetEunaLinks  -- ERROR:', { error });
        throw error;
      }
    },
    staleTime: STALE_TIMES.ONE_DAY,
    ...options,
  });
};
