import amplitude from 'amplitude-js';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { constants } from './settings';
import { history } from './history';
import { getCookie } from './cookie';
import { tokenCookieName } from './constants';
import { getTokenData } from './token';

const AppInsights = new ApplicationInsights({
  config: {
    instrumentationKey: constants.appInsightToken,
    disableCookiesUsage: true,
    /* ...Other Configuration Options... */
  },
});

AppInsights.loadAppInsights();
amplitude.getInstance().init(constants.amplitudeToken);
amplitude.getInstance().init(constants.amplitudeToken, null, {
  secureCookie: true,
  sameSiteCookie: 'Strict',
});

//initialize both appinsights and google analytics with necessary token
const initializeTelemetryProviders = () => {
  // AppInsights.downloadAndSetup({ instrumentationKey: constants.appInsightToken })

  ReactGA.initialize(constants.gaToken, {
    testMode: process.env.NODE_ENV !== 'production',
    gtagOptions: {
      custom_map: {
        dimension1: 'dimension1',
        dimension2: 'dimension2',
      },
      cookie_flags: 'Secure;SameSite=Strict',
    },
  });

  /*ReactGA.initialize(constants.gaToken, {
    testMode: process.env.NODE_ENV !== 'production',
  });*/
  TagManager.initialize({
    gtmId: constants.gtmToken,
  });
};

initializeTelemetryProviders();

//method for app use
const trackEvent = (event: any, data: any) => {
  if (process.env.NODE_ENV !== 'test') {
    AppInsights.trackEvent({ name: event, properties: data });
    // //ga has only 20 free and 200 premium custom dimensions, so just track event name for now
    // ReactGA.event({
    // 	category: 'Application',
    // 	action: event
    // 	//...data
    // })
  }
};

export const track = (event: string, data: any) => {
  if (process.env.NODE_ENV === 'test') return;

  const token = getCookie(tokenCookieName);
  const pageUrl = window?.location?.href;
  let payloadData = { ...data, pageUrl };

  if (token) {
    const tokenData = getTokenData(token);
    if (tokenData?.token?.length) {
      payloadData = { ...payloadData, us: tokenData.accountId, mi: tokenData.memberId, opi: tokenData.opsId };
    }
  }

  trackEvent(event, payloadData);
};

//page view method
export const pageview = (historyLocation: any) => {
  if (process.env.NODE_ENV !== 'test') {
    const location = window.location;
    AppInsights.trackPageView();
    track('PAGE_LOAD', { page: location.pathname + location.search });
  }
};

const setAmplitudeUserProperties = (properties: any) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const trackAmplitudeUserAttributes = async (
  eventTitle: string,
  eventDesc: string,
  paramData: any,
  userData: any,
) => {
  await setAmplitudeUserProperties(userData);
  trackInAmplitude(eventTitle, { eventDescription: eventDesc, payload: paramData });
};

export const TrackGAEvents = (event: {
  action: string;
  category: string;
  label: string;
  // dimension1: string;
  // dimension2: string;
}) => {
  ReactGA.event(event.action, {
    event_category: event.category,
    label: event.label,
    // dimension1: event.dimension1,
    // dimension2: event.dimension2,
  });
};

export const trackInAmplitude = async (event: string, data: any) => {
  let eventProperties = {} as any;
  let eventName = '';
  const subscriptionType = `${
    data.subscriptionData && data.subscriptionData.length > 0
      ? 'Paid'
      : data.freeAgencyInfo && data.freeAgencyInfo.agencyName
      ? 'Free Agency'
      : 'Basic'
  } Subscriber`;
  let productsList = {};
  if (data.subscriptionData)
    productsList = data.subscriptionData.map((item: any) => item.productName);
  let companyInfo = {};
  if (data.companyAddress) {
    companyInfo = {
      /* address1: data.companyAddress.address1,
        address2: data.companyAddress.address2,  */
      'City (Address)': data.companyAddress.city,
      'State (Address)':
        data.companyAddress.stateName && data.companyAddress.stateName.label
          ? data.companyAddress.stateName.label
          : '',
      'County (Address)':
        data.companyAddress.countyName && data.companyAddress.countyName.label
          ? data.companyAddress.countyName.label
          : '',
      /*       country: (data.companyAddress.country && data.companyAddress.country.label)?data.companyAddress.country.label:'' */
    };
  }

  switch (event) {
    case 'ChooseSubscription':
      eventName = 'registration (supplier) - select agency';
      eventProperties = {
        /* description: 'Select a free agency',  */
        'Free Agency': data.freeAgencyInfo ? data.freeAgencyInfo.agencyName : '',
        'State (Agency)': data.freeAgencyInfo ? data.freeAgencyInfo.agencyState : '',
        'County (Agency)': data.freeAgencyInfo ? data.freeAgencyInfo.agencyCounty : '',
      };
      break;
    case 'CompanyAddress':
      if (data.subscriptionData) {
        eventName = 'registration (supplier) - select product';
        eventProperties = {
          /* description: 'Select one or more products', */ 'Member Type': subscriptionType,
          'Product Name': productsList,
        };
      }
      break;
    case 'MainContactInfo':
      if (data.companyAddress) {
        eventName = 'registration (supplier) - submit company info';
        eventProperties = {
          /* description: 'Add company info', */ 'Member Type': subscriptionType,
          'Product Name': productsList,
          ...companyInfo,
        };
      }
      break;
    case 'AccountConfirmation':
    case 'ReviewOrder':
      if (data.mainContact && data.mainContact.firstName) {
        eventName = 'registration (supplier) - submit contact info';
        eventProperties = {
          /* description: 'Add their contact info', */ 'Member Type': subscriptionType,
          'Product Name': productsList,
          ...companyInfo,
        };
      }
      break;
    case 'RegistrationAddPayment':
      eventName = 'registration (supplier) - add payment method';
      eventProperties = {
        /* description: 'Add a payment method', */ 'Member Type': subscriptionType,
        'Product Name': productsList,
        ...companyInfo,
      };
      break;
    case 'OrderConfirmation':
      eventName = 'registration (supplier) - place order';
      eventProperties = {
        /* description: 'Complete checkout', */ 'Member Type': subscriptionType,
        'Product Name': productsList,
        ...companyInfo,
      };
      break;
    default:
      eventName = event;
      eventProperties = { /* description: data.Description, */ ...data.payload };
  }
  if (!['PrintReceipt', 'CreateAccount', 'EmailAccount'].includes(eventName)) {
    await setAmplitudeUserProperties({
      Email: data.emailAddress,
      'Organization Name': data.companyName,
    });
    amplitude.getInstance().logEvent(eventName, eventProperties);
  }
};

export const trackRegistrationPage = (path: string, data: any) => {
  ReactGA.event('Registration', {
    path,
    ...data,
  });
};
export const trackSubscriptions = (path: string, data: any) => {
  ReactGA.event('subscription', {
    path,
    ...data,
  });
};


//track all router page views
history.listen(location => {
  pageview(location);
});

//for adding custom properties
// const setDimensions = data => {
// 	ReactGA.set(...data)
// }

//app insight add default custom collectors
//appInsights.addTelemetryInitializer(telemetryInitializer)

try {
  window.addEventListener('error', (error: ErrorEvent) => {
    track('JS_ERROR', error);
  });

  window.addEventListener('DOMContentLoaded', () => {
    //track initial page view
    pageview(window.location);
  });
} catch (error) {
  track('JS_ERROR', error);
}
