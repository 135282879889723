import React, { useContext, useEffect } from 'react';
import {
  bidsDataConstantsLeftMenu,
  MemberType,
  quotesDataConstantsLeftMenu,
} from '../../../utils/constants';
import { noresultFound } from '../../../utils/texts';
import { NoResult } from '../../customcontrols/index';
import DashboardSummaryItem from './dashboardsummaryitem';
import { bidstatParamType } from '../../../types/commoditycodes';
import { useAuthContext } from 'src/auth/AuthProvider';
import { useGetDashboardBidsListData } from 'src/query/queries/getDashboardBidsListData';
import { useGetDashboardQuotesListData } from 'src/query/queries/getDashboardQuotesListData';
import { toastFn } from 'src/utils/helpers';
import { AppContext } from 'src/global-context/AppContext';

interface PropsTypes {
  shared?: any;
  getDashboardBidsList?: any;
  getDashboardQuotesList?: any;
  history?: any;
  getBids?: any;
  setBidDetails?: any;
  getQuotesList?: any;
  setQuotesDetails?: any;
  dashboardData?: any;
  statistics?: any;
}

function DashboardLeftPanel(props: PropsTypes) {
  const { history, getBids, setBidDetails, getQuotesList, setQuotesDetails, statistics } = props;
  const { auth } = useAuthContext();
  const { fetchDashboardData, RefetchDashboardData } = useContext(AppContext);
  let dashboardBids: bidstatParamType[] = [];
  let dashboardQuotes: bidstatParamType[] = [];
  const { bids = bidsDataConstantsLeftMenu, quotes = quotesDataConstantsLeftMenu } =
    statistics || {};

  const {
    isSuccess: bidsListSuccess,
    isError: bidListError,
    isLoading,
    isFetching,
    data: dashboadBidsList,
    refetch: refetchDashboardBids,
  } = useGetDashboardBidsListData({});
  const {
    isSuccess: quotesListSuccess,
    isError: quotesListError,
    data: dashboadQuotesList,
    refetch: refetchDashboardQuotes,
  } = useGetDashboardQuotesListData({});

  useEffect(() => {
    if (fetchDashboardData) {
      refetchDashboardBids();
      refetchDashboardQuotes();
      RefetchDashboardData(false);
    }
  }, [refetchDashboardBids, refetchDashboardQuotes, RefetchDashboardData, fetchDashboardData]);

  if (bidsListSuccess) dashboardBids = dashboadBidsList?.data?.result;
  else if (bidListError)
    toastFn('error', 'Failed to retrieve dashboard data.Please try later. ', true);

  if (quotesListSuccess) dashboardQuotes = dashboadQuotesList?.data?.result;
  else if (quotesListError)
    toastFn('error', 'Failed to retrieve dashboard data.Please try later. ', true);

  return (
    <>
      {bids.length > 0 ? (
        <>
          <h3 className='lineHR' data-testid='dashboard.mybids.head'>
            My Bids
          </h3>
          {bids.map(
            (item: bidstatParamType, index: number) =>
              (MemberType.AgencyBuyer === auth?.memberType ||
                !['MB', 'UP', 'OP'].includes(item.categoryType)) && ( // should these be BidCategory enums?
                <DashboardSummaryItem
                  key={index}
                  dashboardBids={dashboardBids}
                  pagefor='bids'
                  item={item}
                  memberType={auth?.memberType}
                  history={history}
                  getBids={getBids}
                  setBidDetails={setBidDetails}
                />
              ),
          )}
        </>
      ) : (
        <NoResult message={noresultFound} />
      )}
      {auth?.memberType !== MemberType.BasicSupplier ? (
        quotes.length > 0 ? (
          <>
            <h3 className='lineHR mt-4' data-testid='dashboard.myquotes.head'>
              My Quotes
            </h3>
            {quotes.map(
              (item: any, index: any) =>
                (MemberType.AgencyBuyer === auth?.memberType ||
                  !['MQ', 'IN'].includes(item.categoryType)) && (
                  <DashboardSummaryItem
                    key={index}
                    dashboardQuotes={dashboardQuotes}
                    pagefor='quotes'
                    item={item}
                    memberType={auth?.memberType}
                    history={history}
                    getQuotesList={getQuotesList}
                    setQuotesDetails={setQuotesDetails}
                  />
                ),
            )}
          </>
        ) : (
          <NoResult message={noresultFound} />
        )
      ) : null}
    </>
  );
}

export default DashboardLeftPanel;
