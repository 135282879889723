import React from 'react';
import { useQuery } from '@tanstack/react-query'; // Updated import
import { getOtherAccountInfo } from 'src/store/services';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';
import { track } from '../../utils/telemetry';

const GetAccountInfoCommodity = () => {
  return getOtherAccountInfo();
};

export const useGetAccountInfoCommodity = () => {
  return useQuery({
    queryKey: ['AccountInfoCommodity'],
    queryFn: async () => {
      try {
        return await  GetAccountInfoCommodity();
      } catch (error) {
        track('GetAccountInfoCommodity  -- ERROR:', { error });
        throw error;
      }
    },
    staleTime: STALE_TIMES.THIRTY_MINUTES,
  });
};
