import { Link } from 'react-router-dom';
import { displayDate, getDisplayText, getStatusBarTheme } from '../../../../../utils/helpers';
import { NoResult } from '../../../../customcontrols';

export const RelevantCognitiveBids = ({ bidTagsData,isSearchClicked,pageFor}) => {
    const ListItem = ({ label, value }: { label: string; value: any }) => (
        <li className='list-inline-item'>
            <i>{label}:</i> {value}
        </li>
    );
    return (
        <>
            {bidTagsData && bidTagsData?.value?.length > 0 ? (
                <>
                    <h4 className='mt-1'>Relevant Bids {!isSearchClicked ? 'based on Selected Tags' : null}</h4>
                    {bidTagsData?.value.slice(0, 5).map((bid, index) => (
                        <div
                            key={bid.bidid}
                            className={`listGroupWrapper clearfix ${index >= 3 ? 'blurred' : ''}`}
                        >
                            {pageFor === 'registration'?
                            <h5 className='mw-100 text-truncate' title={bid.title}>
                            <Link
                                className='w-75 text-truncate float-left'
                                to={`/limited/bids/${bid.bidid}/details`}
                                target='_blank'
                            >
                                {bid.title}
                            </Link>
                            <span className={getStatusBarTheme(bid.bidStatus)}>{getDisplayText(bid.bidStatus)}</span>

                        </h5>:
                        <h5 className='mw-100 text-truncate' title={bid.title}>
                        <a
                            className='w-75 text-truncate float-left cursorPointer'


                        >
                            {bid.title}
                        </a>
                        <span className={getStatusBarTheme(bid.bidStatus)}>{getDisplayText(bid.bidStatus)}</span>

                    </h5>
                        }

                            <ul className='list-inline d-flex justify-content-start'>
                                <p>{bid.agency}</p>
                            </ul>
                            <ul className='list-inline'>
                                <ListItem label='ID' value={bid.BidIdentifier || ''} />
                                <ListItem
                                    label='Broadcast'
                                    value={bid.broadcastdate
                                        ? displayDate(bid.broadCastDate, bid.tzfn) : null}
                                />
                                <ListItem
                                    label='Due'
                                    value={bid.duedate
                                        ? displayDate(bid.dueDate, bid.tzfn) : null}
                                />
                            </ul>
                        </div>
                    ))}
                </>
            ) : isSearchClicked ? (
                <NoResult message={'No relevant bids found based on your search criteria.'} />
            ) : !isSearchClicked && pageFor !== 'registration' ? <>
                <h4 className='mt-1'>Relevant Bids </h4>
                <NoResult message={' Search to see Relavent bids .'} /> </> : null
            }

        </>
    );
};
